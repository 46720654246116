import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const BOQTable = ({ items }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <th className="py-3 px-6 text-left">Product</th>
            <th className="py-3 px-6 text-left">Unit</th>
            <th className="py-3 px-6 text-left">Quantity</th>
            <th className="py-3 px-6 text-left">Make</th>
          </tr>
        </thead>
        <tbody className="text-gray-600 text-sm font-light">
          {items.map((item, index) => (
            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
              <td className="py-3 px-6 text-left whitespace-nowrap">{item.product}</td>
              <td className="py-3 px-6 text-left">{item.unit}</td>
              <td className="py-3 px-6 text-left">{item.quantity}</td>
              <td className="py-3 px-6 text-left">{item.make}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const BOQSection = ({ section, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full p-4 text-left font-semibold text-lg bg-white border border-gray-200 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200 flex justify-between items-center"
      >
        <span>{section}</span>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="mt-2 p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
              <BOQTable items={items} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const BOQDisplay = () => {
  const [boqData, setBoqData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { projectId } = useParams();
  const location = useLocation();
  const { project } = location.state || {};

  useEffect(() => {
    const fetchBOQData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/api/projects/boq/${projectId}`);
        setBoqData(response.data.project.boq);
      } catch (err) {
        setError('Failed to fetch BOQ data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBOQData();
  }, [projectId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600 mt-8">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-2 text-center text-gray-800">{project.Project_Name}</h1>
      <h2 className="text-2xl font-semibold mb-6 text-center text-gray-600">Bill of Quantities</h2>
      
      {Object.entries(boqData).map(([section, items]) => (
        <BOQSection key={section} section={section} items={items} />
      ))}
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="mt-8 text-center"
      >
        <p className="text-gray-600">
          This BOQ was last updated on: {new Date().toLocaleDateString()}
        </p>
      </motion.div>
    </div>
  );
};

export default BOQDisplay;