import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomerDetails from '../CommonFormComponents/customerDetails';
import Letterhead from '../CommonFormComponents/letterHead/letterHead';
import DownloadButton from '../CommonFormComponents/downloadButton';
import Heading from '../CommonFormComponents/Heading';

export default function FirePumpRoom() {
  const location = useLocation();
  const { survey } = location.state || {};
  const pumpData = survey.system;

  if (!pumpData) {
    return <div className="text-center text-red-500">No Fire Pump Room data available.</div>;
  }

  const pumpHeaders = [
    { text: 'Type Of Service', colSpan: 1 },
    { text: '1) Installation', colSpan: 3 },
    { text: '2) Warranty', colSpan: 2 },
    { text: '3) Break Down', colSpan: 2 },
    { text: '4) Preventive', colSpan: 1 },
  ];

  const pumpSubHeaders = [
    'PUMP', 'Capacity', 'Cut-In Pr.', 'Cut-Out Pr.', 'Starting Current', 'Running Current',
    'Switch (Auto/Manual)', 'Gland', 'Callb. Pr. Switch'
  ];

  const pumpRows = [
    {
      name: 'Jockey Pump Hydrant Kp/HP',
      data: pumpData.Jockey_Pump_Hydrant
    },
    {
      name: 'Motor Driven Pump Hydrant Kw/HP',
      data: pumpData.Motor_Driven_Pump_Hydrant
    },
    {
      name: 'Motor Driven Pump Sprinkler Kw/HP',
      data: pumpData.Motor_Driven_Pump_Sprinkler
    },
    {
      name: 'Diesel Driven Pump Hydrant Kw/HP',
      data: pumpData.Diesel_Driven_Pump_Hydrant
    }
  ];

  const engineChecksHeaders = ['Fire Engine Checks List'];
  const engineChecksSubHeaders = ['Description', 'Checked', 'Parameter', 'Remarks'];

  const engineChecksRows = [
    { name: 'Check Diesel Tank Level', data: pumpData.Fire_Engine?.Diesel_Tank_Level },
    { name: 'Check Battery Voltage', data: pumpData.Fire_Engine?.Voltage },
    { name: 'Electroltte Level in Battery', data: pumpData.Fire_Engine?.Elec_Level },
    { name: 'SMPS Battery Charger Voltage', data: pumpData.Fire_Engine?.Smps_Voltage },
    { name: 'Battery Installtion Date', data: pumpData.Fire_Engine?.Battery_Install_Date },
    { name: 'Battery Terminals Connection', data: pumpData.Fire_Engine?.Terminals_Con },
    { name: 'Total Running Hours', data: pumpData.Fire_Engine?.Running_Hrs },
    { name: 'Hours Run Since last "B" Check', data: pumpData.Fire_Engine?.Last_B_Check },
    { name: 'Engine Start Time', data: pumpData.Fire_Engine?.Start_Time },
    { name: 'Engine Stop Time', data: pumpData.Fire_Engine?.End_Time },
    { name: 'Check Engine Oil Pr. (Kg/Cm2)', data: pumpData.Fire_Engine?.Engine_Oil },
    { name: 'Check Water Temp ©', data: pumpData.Fire_Engine?.Water_Temp },
    { name: 'Check Coolant Level in Radiator', data: pumpData.Fire_Engine?.Coolant_Level }
  ];

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />
      <Heading title="Survey Report" />
      <CustomerDetails survey={survey} />
      <Heading title="Fire Pump Room" />

      {/* Pump Table */}
      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              {pumpHeaders.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-center" colSpan={header.colSpan}>
                  {header.text}
                </th>
              ))}
            </tr>
            <tr className="bg-gray-100">
              {pumpSubHeaders.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left border">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pumpRows.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="p-3 text-sm border">{row.name}</td>
                <td className="p-3 text-sm border">{row.data?.Capacity || 'NA'}</td>
                <td className="p-3 text-sm border">{row.data?.Cut_In_Pressure || 'NA'}</td>
                <td className="p-3 text-sm border">{row.data?.Cut_Off_Pressure || 'NA'}</td>
                <td className="p-3 text-sm border">{row.data?.Starting_Current || 'NA'}</td>
                <td className="p-3 text-sm border">{row.data?.Running_Current || 'NA'}</td>
                <td className="p-3 text-sm border">{row.data?.Switch_Type || 'NA'}</td>
                <td className="p-3 text-sm border">{row.data?.Gland || 'NA'}</td>
                <td className="p-3 text-sm border">{row.data?.Callbox_Switch || 'NA'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      <br></br>

      {/* Fire Engine Checks List Table */}
      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead>
            <tr className="bg-gray-200">
              {engineChecksHeaders.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-center" colSpan={4}>
                  {header}
                </th>
              ))}
            </tr>
            <tr className="bg-gray-100">
              {engineChecksSubHeaders.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {engineChecksRows.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="p-3 text-sm border">{row.name}</td>
                <td className="p-3 text-sm border">{row.data !== undefined ? 'Yes' : 'No'}</td>
                <td className="p-3 text-sm border">{row.data || ''}</td>
                <td className="p-3 text-sm border">
                  {pumpData.Fire_Engine?.[`${row.name.replace(/\s+/g, '_').replace(/[()]/g, '')}_Reason`] || 'NA'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <h4 className="text-lg font-semibold mb-2">Service Engineers Remarks:</h4>
        <p>{pumpData.remark || 'NA'}</p>
      </div>

      <div className="flex justify-center">
        <DownloadButton 
          reportId={survey.report_id} 
          clientName={survey.customerDetails?.client_name} 
          type="amc" 
        />
      </div>
    </div>
  );
}