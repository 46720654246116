import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const TubeSuppressionSystem = ({ tubes = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = tubes.some(tube => tube.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Tube Suppression System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                <th className="p-3 text-sm font-semibold text-left">Application area</th>
                <th className="p-3 text-sm font-semibold text-left">Refilling required</th>
                <th className="p-3 text-sm font-semibold text-left">Cylinder Capacity</th>
                <th className="p-3 text-sm font-semibold text-left">System Make</th>
                <th className="p-3 text-sm font-semibold text-left">System Type</th>
                <th className="p-3 text-sm font-semibold text-left">Type of gas</th>
                <th className="p-3 text-sm font-semibold text-left">Overall Working Condition Of System</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {tubes.map((tube, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{tube.App_Area || tube.Other_Area || '---'}</td>
                  <td className="p-3 text-sm border">
                    {tube.Refill_Req === 'Yes' 
                      ? `Last Refilled: ${tube.Last_Refilled_Date}\nNext Refill Date: ${tube.Next_Refill_Date}`
                      : tube.Refill_Req}
                  </td>
                  <td className="p-3 text-sm border">{tube.Cyl_Cap ? `${tube.Cyl_Cap} Kg` : '---'}</td>
                  <td className="p-3 text-sm border">{tube.Make || '---'}</td>
                  <td className="p-3 text-sm border">{tube.System_Type || '---'}</td>
                  <td className="p-3 text-sm border">{tube.Gas_Type || '---'}</td>
                  <td className="p-3 text-sm border">{tube.Working_Cond === 'No' ? tube.Remark : '---'}</td>
                  <td className="p-3 text-sm border">{tube.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations for Tube Suppression System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">Application Area</th>
                    <th className="p-3 text-sm font-semibold text-left">Length of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Width of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Height of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Compartments</th>
                    <th className="p-3 text-sm font-semibold text-left">Type of Gas</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {tubes
                    .filter(tube => tube.New_System_Rec === 'Yes')
                    .flatMap((tube, tubeIndex) => {
                      const newSystems = Array.isArray(tube.New_Systems) ? tube.New_Systems : [tube.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${tubeIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{tubeIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.App_Area || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Panel_Length ? `${newSystem.Panel_Length} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Panel_Width ? `${newSystem.Panel_Width} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Panel_Height ? `${newSystem.Panel_Height} mm` : '---'}</td>
                          <td className="p-3 text-sm border">
                            {newSystem?.Compartments === 'Yes' 
                              ? `No Of Compartments: ${newSystem.No_Of_Compartments}
                                 Largest Comp. Length: ${newSystem.Largest_Comp_Length} mm
                                 Largest Comp. Height: ${newSystem.Largest_Comp_Height} mm
                                 Largest Comp. Width: ${newSystem.Largest_Comp_Width} mm`
                              : newSystem?.Compartments}
                          </td>
                          <td className="p-3 text-sm border">{newSystem?.Gas_Type || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TubeSuppressionSystem;