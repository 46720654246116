import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const PublicAddressSystem = ({ addresses = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = addresses.some(address => address.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Public Address System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                <th className="p-3 text-sm font-semibold text-left">Amplifier Capacity (in Watts)</th>
                <th className="p-3 text-sm font-semibold text-left">Mixer</th>
                <th className="p-3 text-sm font-semibold text-left">Make</th>
                <th className="p-3 text-sm font-semibold text-left">Speakers</th>
                <th className="p-3 text-sm font-semibold text-left">No. of any other speaker</th>
                <th className="p-3 text-sm font-semibold text-left">Microphone Quantity</th>
                <th className="p-3 text-sm font-semibold text-left">Volume Controller Quantity</th>
                <th className="p-3 text-sm font-semibold text-left">Overall Working Condition of System</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {addresses.map((address, index) => {
                const speakers = address.Speakers || [];
                const speakerCount = Math.max(speakers.length, 1);
                return (
                  <React.Fragment key={index}>
                    <tr className="hover:bg-gray-50 transition-colors duration-150">
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{index + 1}</td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Amp_Capacity || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Mixer || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Make || '---'}</td>
                      <td className="p-3 text-sm border">
                        {speakers.length > 0 ? 
                          `Type: ${speakers[0].Type}\nQuantity: ${speakers[0].Quantity}\nCapacity: ${speakers[0].Capacity}` : '---'}
                      </td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Other_Speaker || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Microphone || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Volume_Controller || '---'}</td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Working_Cond === 'No' ? address.Remark : address.Working_Cond}</td>
                      <td className="p-3 text-sm border" rowSpan={speakerCount}>{address.Write_Remarks || '---'}</td>
                    </tr>
                    {speakers.slice(1).map((speaker, speakerIndex) => (
                      <tr key={`${index}-${speakerIndex}`} className="hover:bg-gray-50 transition-colors duration-150">
                        <td className="p-3 text-sm border">
                          {`Type: ${speaker.Type}\nQuantity: ${speaker.Quantity}\nCapacity: ${speaker.Capacity}`}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations for Public Address System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">Length of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Width of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Height of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {addresses
                    .filter(address => address.New_System_Rec === 'Yes')
                    .flatMap((address, addressIndex) => {
                      const newSystems = Array.isArray(address.New_Systems) ? address.New_Systems : [address.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${addressIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{addressIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Length_Of_Premise ? `${newSystem.Length_Of_Premise} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Width_Of_Premise ? `${newSystem.Width_Of_Premise} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Height_Of_Premise ? `${newSystem.Height_Of_Premise} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PublicAddressSystem;