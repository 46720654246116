import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { Eye, Calendar, User, MapPin, ChevronRight, PlusCircle, Search, FileX } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;

export default function AMCManagement() {
    const [activeTab, setActiveTab] = useState('Ongoing');
    const [amcs, setAMCs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isManager, setIsManager] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        fetchAMCs();
        checkUserRole();
    }, [activeTab]);

    const fetchAMCs = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${API_URL}/api/amcs/${activeTab}`);
            setAMCs(response.data.amcs);
        } catch (err) {
            setError('Failed to fetch AMCs. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const checkUserRole = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setIsManager(decodedToken.role === 'Manager');
            } catch (error) {
                console.error('Error decoding token:', error);
                setIsManager(false);
            }
        }
    };

    const tabs = [
        { id: 'Ongoing', label: 'On Going AMCs' },
        { id: 'Completed', label: 'Completed AMCs' },
    ];

    const handleFileOpen = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    const handleInitializeAMC = () => {
        navigate('/amc/amc-contract');
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        if (value.trim() !== '' || value === '') {
            setSearchQuery(value);
        }
    };

    const filteredAMCs = amcs.filter((amc) =>
        amc.AMC_Name.toLowerCase().includes(searchQuery.toLowerCase().trim()) ||
        amc.Buyer.Name.toLowerCase().includes(searchQuery.toLowerCase().trim())
    );

    return (
        <div className="container mx-auto px-4 py-12 max-w-6xl">
            <motion.h1 
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="text-4xl font-bold mb-8 text-gray-800 text-center"
            >
                AMC Management
            </motion.h1>
            <motion.div 
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="mb-8"
            >
                <div className="flex justify-between items-center">
                    <div className="flex space-x-4">
                        {tabs.map((tab) => (
                            <motion.button
                                key={tab.id}
                                onClick={() => setActiveTab(tab.id)}
                                className={`${
                                    activeTab === tab.id
                                        ? 'bg-primary text-primary-foreground shadow-lg'
                                        : 'bg-background text-muted-foreground hover:bg-muted'
                                } px-6 py-3 rounded-full font-medium text-sm transition-all duration-300 ease-in-out`}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                {tab.label}
                            </motion.button>
                        ))}
                    </div>
                    <div className="flex-grow mx-4 relative">
                        <input
                            type="text"
                            placeholder="Search AMCs by name or client"
                            value={searchQuery}
                            onChange={handleSearch}
                            className="w-full px-4 py-2 pl-10 pr-4 text-gray-700 bg-white border rounded-full focus:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
                        />
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                    </div>
                    {isManager && (
                        <motion.button
                            onClick={handleInitializeAMC}
                            className="bg-black text-white px-6 py-3 rounded-full font-medium text-sm transition-all duration-300 ease-in-out hover:bg-gray-800 flex items-center whitespace-nowrap"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <PlusCircle size={18} className="mr-2" />
                            Initialize AMC
                        </motion.button>
                    )}
                </div>
            </motion.div>

            <AnimatePresence mode="wait">
                <motion.div
                    key={activeTab}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                >
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
                        </div>
                    ) : error ? (
                        <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg">{error}</div>
                    ) : filteredAMCs.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {filteredAMCs.map((amc) => (
                                <motion.div
                                    key={amc.amc_id}
                                    className="bg-card text-card-foreground shadow-lg rounded-xl overflow-hidden hover:shadow-xl transition-shadow duration-300"
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <div className="p-6 space-y-4">
                                        <div className="flex justify-between items-start">
                                            <h3 className="text-xl font-semibold text-primary truncate">{amc.AMC_Name}</h3>
                                            {amc.Uploaded_File && (
                                                <motion.button
                                                    onClick={() => handleFileOpen(amc.Uploaded_File)}
                                                    className="p-2 bg-primary text-primary-foreground rounded-full hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200"
                                                    title="View Contract"
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                >
                                                    <Eye size={18} />
                                                </motion.button>
                                            )}
                                        </div>
                                        <div className="space-y-2 text-sm text-muted-foreground">
                                            <div className="flex items-center">
                                                <User size={16} className="mr-2" />
                                                <span>AMC ID: {amc.amc_id}</span>
                                            </div>
                                            <div className="flex items-center">
                                                <User size={16} className="mr-2" />
                                                <span>Client: {amc.Buyer.Name}</span>
                                            </div>
                                            <div className="flex items-center">
                                                <MapPin size={16} className="mr-2" />
                                                <span className="truncate">Location: {amc.Buyer.Address}</span>
                                            </div>
                                            <div className="flex items-center">
                                                <Calendar size={16} className="mr-2" />
                                                <span>Start Date: {amc.date}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-6 py-4 bg-gray-100">
                                        <motion.button
                                            onClick={() => navigate(`/amc/${amc.amc_id}`)}
                                            className="w-full flex justify-between items-center text-sm font-medium text-primary hover:text-primary/90 transition-colors duration-200"
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            View Details
                                            <ChevronRight size={18} />
                                        </motion.button>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    ) : (
                        <motion.div 
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            className="flex flex-col items-center justify-center h-64 bg-white rounded-xl shadow-lg"
                        >
                            <FileX size={48} className="text-gray-400 mb-4" />
                            <h2 className="text-2xl font-semibold text-gray-700 mb-2">No AMCs Found</h2>
                            <p className="text-gray-500 text-center max-w-md">
                                There are no AMCs matching your current filters. Try adjusting your search criteria or initialize a new AMC.
                            </p>
                            {isManager && (
                                <motion.button
                                    onClick={handleInitializeAMC}
                                    className="mt-6 bg-black text-white px-6 py-3 rounded-full font-medium text-sm transition-all duration-300 ease-in-out hover:bg-gray-800 flex items-center"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <PlusCircle size={18} className="mr-2" />
                                    Initialize New AMC
                                </motion.button>
                            )}
                        </motion.div>
                    )}
                </motion.div>
            </AnimatePresence>
        </div>
    );
}