import React, { useState } from 'react';
import axios from 'axios';
import { Download, Loader2, CheckCircle } from 'lucide-react';

const DownloadButton = ({ reportId, clientName, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const downloadPDF = async () => {
    setIsLoading(true);
    setIsCompleted(false);

    try {
      const response = await axios.get(`${API_URL}/api/generate/${type}/${reportId}`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${reportId}_${clientName}.pdf`;

      document.body.appendChild(link);
      link.click();
      link.remove();

      setIsCompleted(true);
      // setTimeout(() => setIsCompleted(false), 3000); // Reset completed state after 3 seconds
    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonClass = () => {
    if (isLoading) {
      return 'bg-yellow-500 hover:bg-yellow-600';
    } else if (isCompleted) {
      return 'bg-green-500 hover:bg-green-600';
    } else {
      return 'bg-blue-500 hover:bg-blue-600';
    }
  };

  return (
    <button 
      className={`flex items-center justify-center w-50 px-4 py-2 text-white rounded-md transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50 ${getButtonClass()}`}
      onClick={downloadPDF}
      disabled={isLoading || isCompleted}
    >
      {isLoading ? (
        <>
          <Loader2 size={18} className="mr-2 animate-spin" />
          Downloading...
        </>
      ) : isCompleted ? (
        <>
          <CheckCircle size={18} className="mr-2" />
          Download Complete
        </>
      ) : (
        <>
          <Download size={18} className="mr-2" />
          Download PDF
        </>
      )}
    </button>
  );
};

export default DownloadButton;