import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomerDetails from '../CommonFormComponents/customerDetails';
import Letterhead from '../CommonFormComponents/letterHead/letterHead';
import DownloadButton from '../CommonFormComponents/downloadButton';
import Heading from '../CommonFormComponents/Heading';

const HoseReel = () => {
  const location = useLocation();
  const { survey } = location.state || {};
  const hoseReel = survey.system;

  if (!hoseReel || hoseReel.hoseReels.length === 0) {
    return <div className="text-center text-red-500">No Fire Hose Reel data available.</div>;
  }

  const headers = [
    'Sr.No', 'Location', 'Water Flow', 'Rubber Hose Integrity', 'Shut off Nozzle',
    'Operting Valve', 'Drum Movement', 'Hose Clamp', 'Leakage Form Valve', 'Remarks'
  ];

  const rows = hoseReel.hoseReels.map((hose_reel, index) => [
    index + 1,
    hose_reel.Location || 'NA',
    hose_reel.Water_Flow || 'NA',
    hose_reel.Rubber_Hose_Int ? `${hose_reel.Capacity} kg` : 'NA',
    hose_reel.Shut_Off_Nozzle || 'NA',
    hose_reel.Operating_Valve || 'NA',
    hose_reel.Drum_Movement || 'NA',
    hose_reel.Hose_Clamp || 'NA',
    hose_reel.Leakage_Form_Valve || 'NA',
    hose_reel.Remarks || 'NA'
  ]);

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />

      <Heading title="Survey Report" />

      <CustomerDetails survey={survey} />

      <Heading title="Fire Hose Reel System" />

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="p-3 text-sm border">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <h4 className="text-lg font-semibold mb-2">Overall Remark:</h4>
        <p>{hoseReel.remark || 'NA'}</p>
      </div>

      <div className="flex justify-center">
        <DownloadButton 
          reportId={survey.report_id} 
          clientName={survey.customerDetails?.client_name} 
          type="amc" 
        />
      </div>
    </div>
  );
};

export default HoseReel;