import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader2, Plus, Trash2 } from 'lucide-react';

const ProductList = [
  'Fire Extinguisher',
  'Fire Hydrant System',
  'Fire Sprinkler System',
  'Fire Alarm / Detection System',
  'Fire Pump Room',
  'Gas Leak Detection System',
  'Public Address System',
  'Water Leak Detection System',
  'Rodent Repellent System',
  'Kitchen Suppression System',
  'Room Suppression System',
  'Tube Suppression System'
];

export default function ProjectInitialization() {
  const [project_name, setProjectName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const existingProject = location.state?.project;

  const [Buyer, setBuyer] = useState({
    Name: '',
    Address: '',
    State: '',
    Pincode: '',
    Contacts: [{ Designation: '', Name: '', Email: '', Phone: '' }]
  });

  const [Consignee, setConsignee] = useState({
    Name: '',
    Address: '',
    State: '',
    Pincode: '',
    Contacts: [{ Designation: '', Name: '', Email: '', Phone: '' }]
  });

  const [sameAsBuyer, setSameAsBuyer] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productInput, setProductInput] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const dropdownRef = useRef(null);
  const productListRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  useEffect(() => {
    if (existingProject) {
      setProjectName(existingProject.Project_Name);
      setBuyer(existingProject.Buyer);
      setConsignee(existingProject.Consignee);
      setSelectedProducts(existingProject.Selected_Products);
    }
  }, [existingProject]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (sameAsBuyer) {
      setConsignee(prevConsignee => ({
        ...Buyer,
        Contacts: [...Buyer.Contacts]
      }));
    }
  }, [sameAsBuyer, Buyer]);

  const handleChange = (e, setData) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'Pincode') {
      newValue = value.replace(/\D/g, '').slice(0, 6);
    }

    setData(prev => ({ ...prev, [name]: newValue }));
  };

  const handleContactChange = (index, field, value, setData) => {
    setData(prev => {
      const newContacts = [...prev.Contacts];
      newContacts[index] = { ...newContacts[index], [field]: value };
      return { ...prev, Contacts: newContacts };
    });
  };

  const addContact = (setData) => {
    setData(prev => ({
      ...prev,
      Contacts: [...prev.Contacts, { Designation: '', Name: '', Email: '', Phone: '' }]
    }));
  };

  const removeContact = (index, setData) => {
    setData(prev => ({
      ...prev,
      Contacts: prev.Contacts.filter((_, i) => i !== index)
    }));
  };

  const handleBuyerChange = (e) => {
    handleChange(e, setBuyer);
  };

  const handleConsigneeChange = (e) => {
    if (!sameAsBuyer) {
      handleChange(e, setConsignee);
    }
  };

  const handleSameAsBuyerToggle = () => {
    setSameAsBuyer(!sameAsBuyer);
    if (!sameAsBuyer) {
      setConsignee(Buyer);
    } else {
      setConsignee({
        Name: '',
        Address: '',
        State: '',
        Pincode: '',
        Contacts: [{ Designation: '', Name: '', Email: '', Phone: '' }]
      });
    }
  };

  const handleProductSelect = (product) => {
    if (!selectedProducts.includes(product)) {
      setSelectedProducts(prev => [...prev, product]);
    }
    setProductInput('');
    setShowDropdown(false);
    setFocusedIndex(-1);
  };

  const handleRemoveProduct = (product) => {
    setSelectedProducts(prev => prev.filter(p => p !== product));
  };

  const handleProductInputChange = (e) => {
    setProductInput(e.target.value);
    setShowDropdown(true);
    setFocusedIndex(-1);
  };

  const handleProductInputKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prev => (prev < filteredProducts.length - 1 ? prev + 1 : prev));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prev => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (focusedIndex >= 0 && focusedIndex < filteredProducts.length) {
        handleProductSelect(filteredProducts[focusedIndex]);
      } else if (productInput && !ProductList.includes(productInput)) {
        handleProductSelect(productInput);
      }
    }
  };

  useEffect(() => {
    if (productListRef.current && focusedIndex >= 0) {
      const focusedElement = productListRef.current.children[focusedIndex];
      if (focusedElement) {
        focusedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [focusedIndex]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'application/pdf') {
        setUploadedFile(file);
      } else {
        setAlertMessage('Please upload a PDF file');
        setAlertType('error');
      }
    }
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;
    const pincodeRegex = /^[0-9]{6}$/;

    const validateContacts = (contacts) => {
      return contacts.every(contact => 
        emailRegex.test(contact.Email) && 
        phoneRegex.test(contact.Phone) &&
        contact.Name.trim() !== '' &&
        contact.Designation.trim() !== ''
      );
    };

    if (!validateContacts(Buyer.Contacts) || !validateContacts(Consignee.Contacts)) {
      setAlertMessage('Please enter valid information for all contacts (Name, Designation, Email, and 10-digit Phone number).');
      setAlertType('error');
      return false;
    }

    if (!pincodeRegex.test(Buyer.Pincode) || !pincodeRegex.test(Consignee.Pincode)) {
      setAlertMessage('Please enter valid 6-digit pin codes for both Buyer and Consignee.');
      setAlertType('error');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return
    }

    setIsLoading(true);
    setAlertMessage('');
    setAlertType('');

    let formData;

    if(uploadedFile){
      formData = {
        Project_Name: project_name,
        Buyer: Buyer,
        Consignee: Consignee,
        Selected_Products: selectedProducts,
        Status: 'Ongoing',
        Uploaded_File: uploadedFile
      }
    }else{
      formData = {
        Project_Name: project_name,
        Buyer: Buyer,
        Consignee: Consignee,
        Selected_Products: selectedProducts,
        Status: 'Ongoing'
      }
    }

    if (existingProject) {
      formData.project_id = existingProject.project_id;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/project`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setAlertMessage(existingProject ? 'Project Updated successfully' : 'Project Initialized successfully');
        setAlertType('success');
        setTimeout(() => {
          navigate('/project');
        }, 0);
      }
    } catch (error) {
        console.error('Error submitting project:', error);
        setAlertMessage('Error submitting project. Please try again.');
        setAlertType('error');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredProducts = ProductList.filter(product =>
    product.toLowerCase().includes(productInput.toLowerCase())
  );

  const renderContactFields = (contacts, setData, isConsignee = false) => {
    return contacts.map((contact, index) => (
      <div key={index} className="mb-4 p-4 border border-gray-200 rounded-lg relative">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Designation</label>
            <input
              type="text"
              value={contact.Designation}
              onChange={(e) => handleContactChange(index, 'Designation', e.target.value, setData)}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
              required
              disabled={isConsignee && sameAsBuyer}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              value={contact.Name}
              onChange={(e) => handleContactChange(index, 'Name', e.target.value, setData)}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
              required
              disabled={isConsignee && sameAsBuyer}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={contact.Email}
              onChange={(e) => handleContactChange(index, 'Email', e.target.value, setData)}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
              required
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              title="Please enter a valid email address"
              disabled={isConsignee && sameAsBuyer}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Phone</label>
            <input
              type="tel"
              value={contact.Phone}
              onChange={(e) => handleContactChange(index, 'Phone', e.target.value.replace(/\D/g, '').slice(0, 10), setData)}
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
              required
              pattern="[0-9]{10}"
              title="Please enter a 10-digit phone number"
              disabled={isConsignee && sameAsBuyer}
            />
          </div>
        </div>
        {contacts.length > 1 && !isConsignee && (
          <button
            type="button"
            onClick={() => removeContact(index, setData)}
            className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            title="Remove Contact"
          >
            <Trash2 size={20} />
          </button>
        )}
        {contacts.length > 1 && isConsignee && !sameAsBuyer && (
          <button
            type="button"
            onClick={() => removeContact(index, setData)}
            className="absolute top-2 right-2 text-red-500 hover:text-red-700"
            title="Remove Contact"
          >
            <Trash2 size={20} />
          </button>
        )}
      </div>
    ));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto p-8 rounded-lg shadow-xl"
    >
      <h1 className="text-4xl font-bold mb-8 text-gray-800">
        {existingProject ? 'Edit Project' : 'Project Initialization'}
      </h1>
      {alertMessage && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className={`mb-4 p-4 rounded-md ${alertType === 'success' ? 'bg-green-100  text-green-700' : 'bg-red-100 text-red-700'}`}
        >          
          {alertMessage}
        </motion.div>
      )}
      <form onSubmit={handleSubmit} className="space-y-8">
        <div>
          <label htmlFor="project_name" className="block text-sm font-medium text-gray-700">Project Name</label>
          <input
            type="text"
            id="project_name"
            value={project_name}
            onChange={(e) => setProjectName(e.target.value)}
            className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
            required
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Buyer Information</h2>
            <div className="mb-4">
              <label htmlFor="buyer_Name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="buyer_Name"
                name="Name"
                value={Buyer.Name}
                onChange={handleBuyerChange}
                className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="buyer_Address" className="block text-sm font-medium text-gray-700">Address</label>
              <input
                type="text"
                id="buyer_Address"
                name="Address"
                value={Buyer.Address}
                onChange={handleBuyerChange}
                className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                required
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label htmlFor="buyer_State" className="block text-sm font-medium text-gray-700">State</label>
                <input
                  type="text"
                  id="buyer_State"
                  name="State"
                  value={Buyer.State}
                  onChange={handleBuyerChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                  required
                />
              </div>
              <div>
                <label htmlFor="buyer_Pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                <input
                  type="tel"
                  id="buyer_Pincode"
                  name="Pincode"
                  value={Buyer.Pincode}
                  onChange={handleBuyerChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                  required
                  pattern="[0-9]{6}"
                  title="Please enter a 6-digit pin code"
                />
              </div>
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Contacts</h3>
            {renderContactFields(Buyer.Contacts, setBuyer)}
            <button
              type="button"
              onClick={() => addContact(setBuyer)}
              className="mt-2 p-2 flex items-center bg-green-500 text-white rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              title="Add Contact"
            >
              <Plus size={20} />
              <span className="ml-1">Add New Contact</span>
            </button>
          </div>

          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-semibold text-gray-800">Consignee Information</h2>
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={sameAsBuyer}
                  onChange={handleSameAsBuyerToggle}
                  className="form-checkbox h-5 w-5 text-gray-600"
                />
                <span className="ml-2 text-sm text-gray-700">Same as Buyer</span>
              </label>
            </div>
            <div className="mb-4">
              <label htmlFor="consignee_Name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="consignee_Name"
                name="Name"
                value={Consignee.Name}
                onChange={handleConsigneeChange}
                className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                required
                disabled={sameAsBuyer}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="consignee_Address" className="block text-sm font-medium text-gray-700">Address</label>
              <input
                type="text"
                id="consignee_Address"
                name="Address"
                value={Consignee.Address}
                onChange={handleConsigneeChange}
                className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                required
                disabled={sameAsBuyer}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label htmlFor="consignee_State" className="block text-sm font-medium text-gray-700">State</label>
                <input
                  type="text"
                  id="consignee_State"
                  name="State"
                  value={Consignee.State}
                  onChange={handleConsigneeChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                  required
                  disabled={sameAsBuyer}
                />
              </div>
              <div>
                <label htmlFor="consignee_Pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                <input
                  type="tel"
                  id="consignee_Pincode"
                  name="Pincode"
                  value={Consignee.Pincode}
                  onChange={handleConsigneeChange}
                  className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
                  required
                  disabled={sameAsBuyer}
                  pattern="[0-9]{6}"
                  title="Please enter a 6-digit pin code"
                />
              </div>
            </div>
            <h3 className="text-xl font-semibold mb-2 text-gray-800">Contacts</h3>
            {renderContactFields(Consignee.Contacts, setConsignee, true)}
            {!sameAsBuyer && (
              <button
                type="button"
                onClick={() => addContact(setConsignee)}
                className="mt-2 p-2 flex items-center bg-green-500 text-white rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                title="Add Contact"
              >
                <Plus size={20} />
                <span className="ml-1">Add New Contact</span>
              </button>
            )}
          </div>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Scope of Work</h2>
          <div className="relative" ref={dropdownRef}>
            <input
              type="text"
              value={productInput}
              onChange={handleProductInputChange}
              onKeyDown={handleProductInputKeyDown}
              onFocus={() => setShowDropdown(true)}
              placeholder="Type to search or add new product"
              className="w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-gray-500 focus:ring focus:ring-gray-200 focus:ring-opacity-50 px-4 py-2"
            />
            {showDropdown && (
              <ul ref={productListRef} className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                {filteredProducts.map((product, index) => (
                  <li
                    key={product}
                    onClick={() => handleProductSelect(product)}
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer ${index === focusedIndex ? 'bg-gray-100' : ''}`}
                  >
                    {product}
                  </li>
                ))}
                {productInput && !filteredProducts.includes(productInput) && (
                  <li
                    onClick={() => handleProductSelect(productInput)}
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer font-semibold ${focusedIndex === filteredProducts.length ? 'bg-gray-100' : ''}`}
                  >
                    Add: {productInput}
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="flex flex-wrap gap-2 mt-4">
            <AnimatePresence>
              {selectedProducts.map((product) => (
                <motion.span
                  key={product}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-200 text-gray-800"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  layout
                >
                  {product}
                  <button
                    type="button"
                    onClick={() => handleRemoveProduct(product)}
                    className="ml-2 inline-flex items-center justify-center w-4 h-4 text-gray-600 hover:bg-gray-300 hover:text-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    &times;
                  </button>
                </motion.span>
              ))}
            </AnimatePresence>
          </div>
        </div>

        <div className="border-2 border-gray-300 rounded-lg p-6 bg-white shadow-md">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Upload PO</h2>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileUpload}
            className="block w-full text-sm text-gray-700
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-gray-100 file:text-gray-700
              hover:file:bg-gray-200 cursor-pointer"
          />
          {uploadedFile && (
            <p className="mt-2 text-sm text-gray-600">
              File uploaded: {uploadedFile.name}
            </p>
          )}
        </div>

        <div className="flex justify-center">
          <motion.button
            type="submit"
            disabled={isLoading}
            className={`w-1/4 py-3 px-6 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition-colors duration-200 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin inline-block mr-2" size={20} />
                Submitting...
              </>
            ) : (
              existingProject ? 'Update Project' : 'Submit'
            )}
          </motion.button>
        </div>
      </form>
    </motion.div>
  );
}