import React from "react";
import { ChevronDown, ChevronUp } from 'lucide-react';

const CollapsibleHeading = ({ title, isExpanded, onToggle }) => {
    return (
        <div 
        className="bg-yellow-300 border-2 border-black p-2 my-4 rounded-lg text-center transform transition-transform cursor-pointer relative"
        onClick={onToggle}
        >
            <h3 className="text-xl font-bold">{title}</h3>
            <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
        </div>
    );
};

export default CollapsibleHeading;