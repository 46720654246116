import React from "react";

const CustomerDetails = ({ survey }) => {
    return (
        <div className="overflow-x-auto shadow-md rounded-lg mb-6">
            <table className="w-full bg-white">
                <tbody>
                    <tr className="hover:bg-gray-50">
                        <td className="p-3 text-sm border w-1/2">Name of Client: {survey?.customerDetails?.client_name ?? 'NA'}</td>
                        <td className="p-3 text-sm border">Contact Person Name: {survey?.customerDetails?.contacts[0]?.name ?? 'NA'}</td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                        <td className="p-3 text-sm border w-3/5">Address: {survey?.customerDetails?.address ?? 'NA'}</td>
                        <td className="p-3 text-sm border">Contact Person Tel. No. {survey?.customerDetails?.contacts[0]?.telNo  ?? 'NA'}</td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                        <td className="p-3 text-sm border">Date: {survey?.date}</td>
                        <td className="p-3 text-sm border">Report No: {survey?.report_id}</td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                        <td className="p-3 text-sm border w-1/2">Supervisor Name: {survey?.engineerName ?? 'NA'}</td>
                        <td className="p-3 text-sm border">Supervisor Contact: {survey?.engineerContact ?? 'NA'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default CustomerDetails;