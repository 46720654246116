import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader2 } from 'lucide-react';

ChartJS.register(ArcElement, Tooltip, Legend);

const SurveyTypePieChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div className="bg-white p-4 rounded-lg shadow-lg">No data available for Survey Types</div>;
  }

  const chartData = {
    labels: data.map(item => item.name),
    datasets: [
      {
        data: data.map(item => item.value),
        backgroundColor: [
          '#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#d35400'
        ],
        borderColor: [
          '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Survey Types Distribution',
        font: {
          size: 20,
          weight: 'bold',
        },
        color: '#2c3e50',
        padding: {
          top: 10,
          bottom: 30
        }
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        titleColor: '#2c3e50',
        bodyColor: '#2c3e50',
        bodyFont: {
          size: 16,
          weight: 'bold'
        },
        padding: 12,
        boxPadding: 8,
        borderColor: '#e2e8f0',
        borderWidth: 1,
        callbacks: {
          title: function() {
            return '';
          },
          label: function(context) {
            let value = context.parsed;
            let total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            let percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value} (${percentage}%)`;
          }
        },
        bodyAlign: 'center',
        titleAlign: 'center'
      }
    },
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-white p-6 rounded-xl shadow-lg"
      style={{ height: '450px' }}
    >
      <Doughnut data={chartData} options={options} />
    </motion.div>
  );
};

const EngineerBarChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div className="bg-white p-4 rounded-lg shadow-lg">No data available for Engineer Chart</div>;
  }

  const allKeys = [...new Set(data.flatMap(Object.keys))].filter(key => key !== 'name');

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 8,
        columnWidth: '15%',
        barHeight: '70%',
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
      },
    },
    xaxis: {
      categories: data.map(item => item.name || 'Unknown'),
      labels: {
        rotate: -45,
        rotateAlways: false,
        maxHeight: 120,
      },
    },
    yaxis: {
      title: {
        text: 'Number of Surveys'
      },
    },
    legend: {
      position: 'top',
    },
    title: {
      text: 'Surveys by Engineer',
      align: 'center',
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#263238',
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8'],
    tooltip: {
      y: {
        formatter: function(value) {
          return value !== undefined ? value.toString() : '0';
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
  };

  const series = allKeys.map(key => ({
    name: key,
    data: data.map(item => (typeof item[key] === 'number' ? item[key] : 0))
  }));

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      className="bg-white p-4 rounded-lg shadow-lg"
    >
      <ReactApexChart options={chartOptions} series={series} type="bar" height={450} />
    </motion.div>
  );
};

const MonthlySubmissionsChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <div className="bg-white p-4 rounded-lg shadow-lg">No data available for Monthly Submissions</div>;
  }

  const chartOptions = {
    chart: {
      id: 'monthly-submissions',
      toolbar: {
        show: false,
      },
      background: 'transparent',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    xaxis: {
      categories: data.map(item => item.month || 'Unknown'),
    },
    yaxis: {
      title: {
        text: 'Number of Submissions',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#2c3e50'
        }
      },
    },
    colors: ['#3498db'],
    title: {
      text: 'Monthly Survey Submissions',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#2c3e50',
      },
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100]
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: '#f1f1f1',
    },
  };

  const series = [
    {
      name: 'Submissions',
      data: data.map(item => item.count || 0),
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}
      className="bg-white p-6 rounded-xl shadow-lg"
    >
      <ReactApexChart options={chartOptions} series={series} type="area" height={350} />
    </motion.div>
  );
};

const BaseSurveyDashboard = () => {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [surveyTypeData, setSurveyTypeData] = useState([]);
  const [engineerChartData, setEngineerChartData] = useState([]);
  const [monthlyChartData, setMonthlyChartData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/dashboard-data/Reports`);
        const { surveyTypeData, engineerChartData, monthlyChartData } = response.data;
        
        setSurveyTypeData(surveyTypeData || []);
        setEngineerChartData(engineerChartData || []);
        setMonthlyChartData(monthlyChartData || []);
      } catch (error) {
        console.error('Failed to fetch dashboard data', error);
        setError('Failed to load dashboard data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [API_URL]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-2">
      <SurveyTypePieChart data={surveyTypeData} />
      <MonthlySubmissionsChart data={monthlyChartData} />
      <div className="col-span-1">
        <EngineerBarChart data={engineerChartData} />
      </div>
    </div>
  );
};

const AMCsDashboard = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="bg-white p-8 rounded-lg shadow-lg"
  >
    <h2 className="text-2xl font-bold mb-4 text-gray-800">AMCs Dashboard</h2>
    <p className="text-gray-600">This is a placeholder for the AMCs Dashboard.</p>
  </motion.div>
);

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('base');

  return (
    <div className="container mx-auto p-6 min-h-screen">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-4xl font-bold mb-8 text-gray-800 text-center"
      >
        Survey Dashboard
      </motion.h1>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="mb-8"
      >
        <div className="flex justify-center border-b border-gray-200">
          {/* {['base', 'amcs'].map((tab) => ( */}
          {['base'].map((tab) => (
            <motion.button
              key={tab}
              className={`py-3 px-6 font-medium text-lg transition-colors duration-200 ${
                activeTab === tab
                  ? 'border-b-2 border-primary text-primary'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab(tab)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {tab === 'base' ? 'Base Surveys' : 'AMCs'}
            </motion.button>
          ))}
        </div>
      </motion.div>
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          {activeTab === 'base' && <BaseSurveyDashboard />}
          {activeTab === 'amcs' && <AMCsDashboard />}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Dashboard;