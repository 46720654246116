import React from "react";

const Heading = ({ title }) => {
    return (
        <div className="bg-yellow-300 border-2 border-black p-4 my-6 rounded-lg text-center transform transition-transform hover:scale-105">
            <h3 className="text-2xl font-bold">{title}</h3>
        </div>
    );
}

export default Heading;