import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const FireExtinguishers = ({ extinguishers = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = extinguishers.some(extinguisher => extinguisher.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Extinguishers System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                <th className="p-3 text-sm font-semibold text-left">Location</th>
                <th className="p-3 text-sm font-semibold text-left">Type</th>
                <th className="p-3 text-sm font-semibold text-left">Ct / Sp</th>
                <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                <th className="p-3 text-sm font-semibold text-left">Last Refilled On</th>
                <th className="p-3 text-sm font-semibold text-left">Next Refilling Date</th>
                <th className="p-3 text-sm font-semibold text-left">HPT Due On</th>
                <th className="p-3 text-sm font-semibold text-left">Pressure Gauge</th>
                <th className="p-3 text-sm font-semibold text-left">Hose Pipe</th>
                <th className="p-3 text-sm font-semibold text-left">Nozzle Horn</th>
                <th className="p-3 text-sm font-semibold text-left">Safety Pin</th>
                <th className="p-3 text-sm font-semibold text-left">Body Sticker</th>
                <th className="p-3 text-sm font-semibold text-left">Body Belt</th>
                <th className="p-3 text-sm font-semibold text-left">Valve</th>
                <th className="p-3 text-sm font-semibold text-left">Body Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {extinguishers.map((extinguisher, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{extinguisher.Location || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Type || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Type_Ct_Sp || 'NA'}</td>
                  <td className="p-3 text-sm border">{`${extinguisher.Capacity} ${extinguisher.Capacity_Unit}` || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Last_Refill_Date || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Next_Refill_Date || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.HPT_Due_Date || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Pressure_Gauge || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Hose_Pipe || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Nozzle_Horn || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Safety_Pin || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Body_Sticker || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Body_Belt || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Valve || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Body_Condition || 'NA'}</td>
                  <td className="p-3 text-sm border">{extinguisher.Remark || 'NA'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations of Fire Extinguishers" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Type</th>
                    <th className="p-3 text-sm font-semibold text-left">Capacity</th>
                    <th className="p-3 text-sm font-semibold text-left">Quantity</th>
                    <th className="p-3 text-sm font-semibold text-left">Make</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {extinguishers
                    .filter(extinguisher => extinguisher.New_System_Rec === 'Yes')
                    .flatMap((extinguisher, extinguisherIndex) => {
                      const newSystems = Array.isArray(extinguisher.New_Systems) ? extinguisher.New_Systems : [extinguisher.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${extinguisherIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{extinguisherIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Type || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Capacity && newSystem?.Capacity_Unit ? `${newSystem.Capacity} ${newSystem.Capacity_Unit}` : newSystem?.Other_Capacity || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Quantity || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Make || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Remarks || 'NA'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FireExtinguishers;