// import React from 'react';
// import styles from './letterHead.module.css';
// // import logo from 'logo.png'; // Adjust the path to your logo image

// function Letterhead() {
//   return (
//     <div className={styles.header}>
//       <img className={styles.headerImg} src="logo.png" alt="NFPA India Pvt. Ltd. Logo" />
//       <div className={styles.letterHead}>
//         <div className={styles.headerTitle}>NFPA INDIA PVT. LTD.</div>
//         <p className={`${styles.red} ${styles.bold}`}>
//           GOVERNMENT APPROVED CLASS-A LICENSED AGENCY
//         </p>
//         <p className={styles.letterHeadP}>Building No. 3, Pard B, Sai Nagari, Pune - Alandi Road, Wadamukhwadi, PIN Code - 412105</p>
//         <p className={styles.letterHeadP}>
//           Email Address -{' '}
//           <span className={styles.blue}>sagar.nfpa@gmail.com / aditya@nfpa.in</span>
//         </p>
//         <p className={styles.letterHeadP}>Contact - 9403732325; 8956051385</p>
//         <p className={styles.letterHeadP}>GSTIN - 27AAICN2889B1ZA</p>
//       </div>
//     </div>
//   );
// }

// export default Letterhead;
import React from 'react';
import { MapPin, Phone, Mail } from 'lucide-react';

export default function Letterhead() {
  return (
    <div className="w-full bg-white border-b-8 border-red-600">
      <div className="max-w-7xl mx-auto px-4 py-2 flex items-center">
        {/* Left section - Logo */}
        <div className="w-1/6 mr-4 p-1">
          <img src='/logo.png'></img>
        </div>

        {/* Middle section - Company name and details */}
        <div className="w-3/6 flex flex-col items-start">
          <h1 className="text-5xl font-bold text-red-600 leading-tight">
            SAFETY सारथी
          </h1>
          <h2 className="text-xl font-semibold text-gray-800 leading-tight">
            National Fire Protection Armour
          </h2>
          <p className="text-lg font-bold text-gray-700 mt-1">
            GOVT. APPROVED CLASS-A LICENCED AGENCY
          </p>
        </div>

        {/* Right section - Contact information */}
        <div className="w-3/6 text-xs">
          <div className="flex items-center mb-1">
            <MapPin size={12} className="mr-1" />
            <span className='text-base'>Office : Get No:447, Plot:E-20-30, Maitri Park,</span>
          </div>
          <div className="pl-4 mb-1 text-base">Near Ganesh Samrajya Chowk, Moshi, Pune-412105</div>
          <div className="flex items-center mb-1">
            <Phone size={12} className="mr-1" />
            <span className="mr-2 text-base">7066729911/9673407788</span>
            <img src="/placeholder.svg?height=12&width=12" alt="WhatsApp" className="mr-1" />
            <span className='text-base'>8484060560</span>
          </div>
          <div className="flex items-center mb-1">
            <Mail size={12} className="mr-1" />
            <span className='text-base'>www.safetysaarthi.com</span>
            <span className="mx-1 text-base">|</span>
            <span className='text-base'>Info@safetysaarthi.com</span>
          </div>
          <div className="pl-4 mb-1 text-base">connect@safetysaarthi.com</div>
          <div className="font-semibold text-base">GSTIN - 27AAICN2889B1ZA</div>
        </div>
      </div>
    </div>
  );
}