import React, { useState, useEffect } from 'react';

export default function PrivacyPolicy() {
  const [activeSection, setActiveSection] = useState('');

  const sections = [
    { id: 'introduction', title: '1. Introduction' },
    { id: 'information-we-collect', title: '2. Information We Collect' },
    { id: 'how-we-use-your-information', title: '3. How We Use Your Information' },
    { id: 'data-storage-and-security', title: '4. Data Storage and Security' },
    { id: 'data-sharing-and-disclosure', title: '5. Data Sharing and Disclosure' },
    { id: 'your-rights', title: '6. Your Rights' },
    { id: 'data-retention', title: '7. Data Retention' },
    { id: 'childrens-privacy', title: "8. Children's Privacy" },
    { id: 'changes-to-this-privacy-policy', title: '9. Changes to This Privacy Policy' },
    { id: 'contact-us', title: '10. Contact Us' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const sectionElements = document.querySelectorAll('h2');
      
      for (let i = sectionElements.length - 1; i >= 0; i--) {
        const section = sectionElements[i];
        if (section.offsetTop <= scrollPosition + 100) {
          setActiveSection(section.id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy for Safety सारथी</h1>
      <p className="text-sm text-gray-600 mb-8">Last updated: {new Date().toLocaleDateString()}</p>
      
      <div className="flex flex-col md:flex-row gap-8">
        <nav className="md:w-1/3">
          <h2 className="text-lg font-semibold mb-4">Table of Contents</h2>
          <ul className="space-y-2">
            {sections.map((section) => (
              <li key={section.id}>
                <button
                  onClick={() => scrollToSection(section.id)}
                  className={`text-left w-full py-1 px-2 rounded ${
                    activeSection === section.id ? 'bg-gray-200' : 'hover:bg-gray-100'
                  }`}
                >
                  {section.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        
        <div className="flex-1 space-y-8">
          <section id="introduction">
            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p>Safety सारथी is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platforms for fire protection system management.</p>
            <p className="mt-4">Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the App.</p>
          </section>

          <section id="information-we-collect">
            <h2 className="text-2xl font-bold mb-4">2. Information We Collect</h2>
            <p>We collect information that you provide directly to us when using the App, including:</p>
            <h3 className="text-xl font-semibold mt-4 mb-2">2.1 Personal Information</h3>
            <ul className="list-disc pl-6">
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Company/organization affiliation</li>
              <li>Job title</li>
            </ul>
            <h3 className="text-xl font-semibold mt-4 mb-2">2.2 Survey and Audit Data</h3>
            <ul className="list-disc pl-6">
              <li>Location data of fire protection equipment</li>
              <li>Equipment details (type, quantity, installation date, maintenance dates)</li>
              <li>Site-specific information</li>
            </ul>
            <h3 className="text-xl font-semibold mt-4 mb-2">2.3 Employee Data (for HRMS module)</h3>
            <ul className="list-disc pl-6">
              <li>Attendance records</li>
              <li>Performance reviews</li>
              <li>Payroll information</li>
            </ul>
            <h3 className="text-xl font-semibold mt-4 mb-2">2.4 Customer Data (for CRM module)</h3>
            <ul className="list-disc pl-6">
              <li>Customer contact Information</li>
              <li>Customer GSTIN Information</li>
              <li>Building occupancy details</li>
              <li>Project specifications</li>
            </ul>
            {/* <h3 className="text-xl font-semibold mt-4 mb-2">2.5 Usage Data</h3>
            <ul className="list-disc pl-6">
              <li>App usage statistics</li>
              <li>Log data and device information</li>
            </ul> */}
          </section>

          <section id="how-we-use-your-information">
            <h2 className="text-2xl font-bold mb-4">3. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Providing and maintaining the App's functionality</li>
              <li>Generating reports and proposals</li>
              <li>Managing customer relationships</li>
              <li>Coordinating operations and inventory</li>
              <li>Human resource management</li>
              {/* <li>Accounting and billing</li> */}
              <li>Improving and optimizing the App</li>
              {/* <li>Communicating with you about the App</li> */}
            </ul>
          </section>

          <section id="data-storage-and-security">
            <h2 className="text-2xl font-bold mb-4">4. Data Storage and Security</h2>
            <p>We implement appropriate technical and organizational security measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
          </section>

          <section id="data-sharing-and-disclosure">
            <h2 className="text-2xl font-bold mb-4">5. Data Sharing and Disclosure</h2>
            <p>We may share your information in the following situations:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>With your consent</li>
              <li>To comply with legal obligations</li>
              <li>To protect our rights, privacy, safety, or property</li>
              <li>In connection with a business transfer or merger</li>
            </ul>
          </section>

          <section id="your-rights">
            <h2 className="text-2xl font-bold mb-4">6. Your Rights</h2>
            <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
            <ul className="list-disc pl-6 mt-2">
              <li>Access to your personal information</li>
              {/* <li>Correction of inaccurate information</li> */}
              {/* <li>Deletion of your personal information</li> */}
              <li>Objection to or restriction of processing</li>
            </ul>
            <p className="mt-4">To exercise these rights, please contact us using the information provided in the "Contact Us" section.</p>
          </section>

          <section id="data-retention">
            <h2 className="text-2xl font-bold mb-4">7. Data Retention</h2>
            <p>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required by law.</p>
          </section>

          <section id="childrens-privacy">
            <h2 className="text-2xl font-bold mb-4">8. Children's Privacy</h2>
            <p>The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.</p>
          </section>

          <section id="changes-to-this-privacy-policy">
            <h2 className="text-2xl font-bold mb-4">9. Changes to This Privacy Policy</h2>
            <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Last updated" date.</p>
          </section>

          <section id="contact-us">
            <h2 className="text-2xl font-bold mb-4">10. Contact Us</h2>
            <p>If you have any questions about this privacy policy or our practices, please contact us at:</p>
            <p className="mt-4">
              NFPA (National Fire Protection Armour) India Pvt. Ltd.<br />
              Address: Gat No. 447, Plot: E-20-30, Maitri Park, Near Ganesh Samrajya Chowk, Moshi, Pune - 412105<br />
              Email: info@safetysaarthi.com<br />
              Phone: 7066729911 / 9673407788
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}