import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logout from './Logout';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, ChevronLeft, ChevronRight, ChartColumn, File, User, BriefcaseBusiness, ShieldCheck } from 'lucide-react';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement

export default function NavbarSidebar({ setIsLoggedIn, isSidebarOpen, toggleSidebar }) {
  const [token] = useState(localStorage.getItem('token'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const profileButtonRef = useRef(null);

  useEffect(() => {
    // const token = localStorage.getItem('token'); // Assuming you store the JWT token in localStorage
    if (token) {
      const decodedToken = jwtDecode(token); // Using jwtDecode instead of jwt_decode
      setUserRole(decodedToken.role);
    }

    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, [token]);

  const handleProfileClick = () => {
    setIsDropdownOpen(false);
    navigate('/profile');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !profileButtonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderNavLink = (to, icon, text) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center space-x-2 text-black p-2 rounded-lg ${
          isActive
            ? 'bg-indigo-100 text-indigo-600'
            : 'hover:bg-indigo-50 hover:text-indigo-600'
        }`
      }
    >
      {icon}
      {isSidebarOpen && <span>{text}</span>}
    </NavLink>
  );

  const navLinks = (
    <>
      {userRole === 'Manager' && (
        <>
          {renderNavLink('/dashboard', <ChartColumn size={20} />, 'Dashboard')}
          {renderNavLink('/baseSurveys', <FileText size={20} />, 'Surveys')}
          {renderNavLink('/documents', <File size={20} />, 'Documents')}
        </>
      )}
      {renderNavLink('/amc', <ShieldCheck size={20} />, 'AMC')}
      {renderNavLink('/project', <BriefcaseBusiness size={20}/>, 'Project')}
    </>
  );

  return (
    <>
      <nav className="w-full text-black bg-white fixed top-0 left-0 z-50 h-16 flex items-center justify-between px-4 shadow-lg">
        <div className="flex items-center space-x-4">
          <img src="/logo.png" alt="Company Logo" className="w-12 h-11" />
          <h1 className="text-xl font-bold">Safety सारथी</h1>
        </div>
        <div className="relative">
          <button
            ref={profileButtonRef}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center justify-center w-10 h-10 rounded-full bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <User size={20} className="text-indigo-600" />
          </button>
          <AnimatePresence>
            {isDropdownOpen && (
              <motion.div
                ref={dropdownRef}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50"
              >
                {isSmallScreen && (
                  <div className="px-4 py-2 border-b border-gray-200">
                    {navLinks}
                  </div>
                )}
                <button
                  onClick={handleProfileClick}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100 hover:text-indigo-900"
                >
                  <User size={20} className="inline-block mr-2" />
                  Profile
                </button>
                <div className="p-4 border-t border-gray-200">
                  <Logout setIsLoggedIn={setIsLoggedIn} />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>

      {!isSmallScreen && (
        <aside className={`bg-white text-gray-600 fixed top-16 left-0 h-[calc(100vh-4rem)] flex flex-col shadow-lg transition-all duration-300 ease-in-out z-40 ${
          isSidebarOpen ? 'w-64' : 'w-20'
        }`}>
          <div className="flex-grow p-4 space-y-2 overflow-y-auto">
            {navLinks}
          </div>
          <button
            onClick={toggleSidebar}
            className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-indigo-500 text-white p-1 rounded-full shadow-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 z-50"
          >
            {isSidebarOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
          </button>
        </aside>
      )}
    </>
  );
}