import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const KitchenSuppressionSystem = ({ kitchens = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = kitchens.some(kitchen => kitchen.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Kitchen Suppression System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                <th className="p-3 text-sm font-semibold text-left">Num of KSS Systems</th>
                <th className="p-3 text-sm font-semibold text-left">Hood Length</th>
                <th className="p-3 text-sm font-semibold text-left">Hood Width</th>
                <th className="p-3 text-sm font-semibold text-left">Make of System</th>
                <th className="p-3 text-sm font-semibold text-left">Capacity of Cylinder</th>
                <th className="p-3 text-sm font-semibold text-left">No of Equipment</th>
                <th className="p-3 text-sm font-semibold text-left">Type of Equipment</th>
                <th className="p-3 text-sm font-semibold text-left">Integration with FAS</th>
                <th className="p-3 text-sm font-semibold text-left">Integration with Shut-off Valve</th>
                <th className="p-3 text-sm font-semibold text-left">Integration with Any Other System</th>
                <th className="p-3 text-sm font-semibold text-left">Working Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {kitchens.map((kitchen, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{kitchen.No_Of_Kss || '---'}</td>
                  <td className="p-3 text-sm border">{kitchen.Hood_Length ? `${kitchen.Hood_Length} mm` : '---'}</td>
                  <td className="p-3 text-sm border">{kitchen.Hood_Width ? `${kitchen.Hood_Width} mm` : '---'}</td>
                  <td className="p-3 text-sm border">{kitchen.Make || '---'}</td>
                  <td className="p-3 text-sm border">{kitchen.Capacity && kitchen.Capacity_Unit ? `${kitchen.Capacity} ${kitchen.Capacity_Unit}` : '---'}</td>
                  <td className="p-3 text-sm border">{kitchen.No_Of_Equip || '---'}</td>
                  <td className="p-3 text-sm border">{kitchen.Equip_Type || '---'}</td>
                  <td className="p-3 text-sm border">{kitchen.Int_With_Fas === 'Yes' ? kitchen.Int_With_Fas_Reason : kitchen.Int_With_Fas}</td>
                  <td className="p-3 text-sm border">{kitchen.Int_With_Valve === 'Yes' ? kitchen.Int_With_Valve_Reason : kitchen.Int_With_Valve}</td>
                  <td className="p-3 text-sm border">{kitchen.Int_With_Any_Other === 'Yes' ? kitchen.Int_With_Any_Other_Reason : kitchen.Int_With_Any_Other}</td>
                  <td className="p-3 text-sm border">{kitchen.Working_Cond === 'No' ? kitchen.Remark : kitchen.Working_Cond}</td>
                  <td className="p-3 text-sm border">{kitchen.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations for Kitchen Suppression System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">No. of KSS</th>
                    <th className="p-3 text-sm font-semibold text-left">Hood Length</th>
                    <th className="p-3 text-sm font-semibold text-left">Hood Width</th>
                    <th className="p-3 text-sm font-semibold text-left">No. of Ducts</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {kitchens
                    .filter(kitchen => kitchen.New_System_Rec === 'Yes')
                    .flatMap((kitchen, kitchenIndex) => {
                      const newSystems = Array.isArray(kitchen.New_Systems) ? kitchen.New_Systems : [kitchen.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${kitchenIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm  border">{kitchenIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.No_Of_Kss || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Hood_Length ? `${newSystem.Hood_Length} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Hood_Width ? `${newSystem.Hood_Width} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.No_Of_Ducts ? `${newSystem.No_Of_Ducts} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default KitchenSuppressionSystem;