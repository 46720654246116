import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const FireHydrantSystem = ({ fireHydrants = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = fireHydrants.some(hydrant => hydrant.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Hydrant System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                <th className="p-3 text-sm font-semibold text-left">Single Headed Hydrant Valve</th>
                <th className="p-3 text-sm font-semibold text-left">Double Headed Hydrant Valve</th>
                <th className="p-3 text-sm font-semibold text-left">Single Door Hose Box</th>
                <th className="p-3 text-sm font-semibold text-left">Double Door Hose Box</th>
                <th className="p-3 text-sm font-semibold text-left">Hose Pipe</th>
                <th className="p-3 text-sm font-semibold text-left">Branch Pipe</th>
                <th className="p-3 text-sm font-semibold text-left">2 - Way Inlet</th>
                <th className="p-3 text-sm font-semibold text-left">3 - Way Inlet</th>
                <th className="p-3 text-sm font-semibold text-left">4 - Way Inlet</th>
                <th className="p-3 text-sm font-semibold text-left">Hose Reel Drum</th>
                <th className="p-3 text-sm font-semibold text-left">Working Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {fireHydrants.map((hydrant, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{`${hydrant?.Single_Head_Hyd_Valve_Type} : ${hydrant.Single_Head_Hyd_Valve}` || 'NA'}</td>
                  <td className="p-3 text-sm border">{`${hydrant?.Double_Head_Hyd_Valve_Type} : ${hydrant.Double_Head_Hyd_Valve}` || 'NA'}</td>
                  <td className="p-3 text-sm border">{`${hydrant?.Single_Door_Hose_Box_Type} : ${hydrant.Single_Door_Hose_Box}` || 'NA'}</td>
                  <td className="p-3 text-sm border">{`${hydrant?.Double_Door_Hose_Box_Type} : ${hydrant.Double_Door_Hose_Box}` || 'NA'}</td>
                  <td className="p-3 text-sm border">{`${hydrant?.Hose_Pipe_Type} : ${hydrant.Hose_Pipe}` || 'NA'}</td>
                  <td className="p-3 text-sm border">{`${hydrant?.Branch_Pipe_Type} : ${hydrant.Branch_Pipe}` || 'NA'}</td>
                  <td className="p-3 text-sm border">{hydrant?.Two_Way_Inlet || 'NA'}</td>
                  <td className="p-3 text-sm border">{hydrant?.Three_Way_Inlet || 'NA'}</td>
                  <td className="p-3 text-sm border">{hydrant?.Four_Way_Inlet || 'NA'}</td>
                  <td className="p-3 text-sm border">{hydrant?.Hose_Reel_Drum || 'NA'}</td>
                  <td className="p-3 text-sm border">{hydrant?.Working_Cond === 'No' ? hydrant.Remarks : hydrant.Working_Cond}</td>
                  <td className="p-3 text-sm border">{hydrant?.Write_Remarks || 'NA'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations of Fire Hydrant System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Single Headed Hydrant Valve</th>
                    <th className="p-3 text-sm font-semibold text-left">Double Headed Hydrant Valve</th>
                    <th className="p-3 text-sm font-semibold text-left">Single Door Hose Box</th>
                    <th className="p-3 text-sm font-semibold text-left">Double Door Hose Box</th>
                    <th className="p-3 text-sm font-semibold text-left">Hose Pipe</th>
                    <th className="p-3 text-sm font-semibold text-left">Branch Pipe</th>
                    <th className="p-3 text-sm font-semibold text-left">2 - Way Inlet</th>
                    <th className="p-3 text-sm font-semibold text-left">3 - Way Inlet</th>
                    <th className="p-3 text-sm font-semibold text-left">4 - Way Inlet</th>
                    <th className="p-3 text-sm font-semibold text-left">Hose Reel Drum</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {fireHydrants
                    .filter(hydrant => hydrant.New_System_Rec === 'Yes')
                    .flatMap((hydrant, hydrantIndex) => {
                      const newSystems = Array.isArray(hydrant.New_Systems) ? hydrant.New_Systems : [hydrant.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${hydrantIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{hydrantIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Single_Head_Hyd_Valve || 'NA'}</td>
                          <td className="p-3 text-sm  border">{newSystem?.Double_Head_Hyd_Valve || newSystem?.Other_Capacity || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Single_Door_Hose_Box || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Double_Door_Hose_Box || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Hose_Pipe || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Branch_Pipe || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Two_Way_Inlet || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Three_Way_Inlet || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Four_Way_Inlet || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Hose_Reel_Drum || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Remark || 'NA'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FireHydrantSystem;