import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, Loader2, AlertCircle } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

export default function MaterialRequisition() {
  const [boqData, setBoqData] = useState({});
  const [requisitionData, setRequisitionData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { project } = location.state || {};

  useEffect(() => {
    fetchBOQData();
  }, [projectId]);

  const fetchBOQData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/api/projects/boq/${projectId}`);
      setBoqData(response.data.project.boq);
      initializeRequisitionData(response.data.project.boq);
      // initializeOpenSections(response.data.project.boq);
    } catch (err) {
      console.error('Error fetching BOQ data:', err);
      setError('Failed to fetch BOQ data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const initializeRequisitionData = (boq) => {
    const initialData = {};
    Object.entries(boq).forEach(([section, items]) => {
      items.forEach((item, index) => {
        initialData[`${section}-${index}`] = 0;
      });
    });
    setRequisitionData(initialData);
  };

  const initializeOpenSections = (boq) => {
    const initialOpenSections = {};
    Object.keys(boq).forEach((section) => {
      initialOpenSections[section] = true;
    });
    setOpenSections(initialOpenSections);
  };

  const handleQuantityChange = (section, index, value) => {
    const numValue = Number(value);
    const item = boqData[section][index];
    if (item && numValue >= 0 && numValue <= Number(item.quantity)) {
      setRequisitionData(prev => ({ ...prev, [`${section}-${index}`]: numValue }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const formattedRequisitionData = Object.entries(boqData).reduce((acc, [section, items]) => {
        acc[section] = items.map((item, index) => ({
          ...item,
          requestedQuantity: requisitionData[`${section}-${index}`] || 0
        }));
        return acc;
      }, {});

      const formData = {
        project_id: projectId,
        requisitionData: formattedRequisitionData
      }

      console.log('Requisition Data:', formattedRequisitionData);
      // Here you would typically send the data to your API
      const res = await axios.post(`${API_URL}/api/projects/materialRequisition`, 
        formData, 
        {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${localStorage.getItem('token')}`
          }
        }
      );
      if (res.status === 200) {
        console.log('Requisition submitted successfully:', res.data);
        navigate(`/projects/${projectId}`);
      }
      
    } catch (error) {
      console.error('Error submitting requisition:', error);
      alert('Failed to submit requisition. Please try again.');
      // setError('Failed to submit requisition. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const toggleSection = (section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <Loader2 size={48} className="text-black animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 flex items-center space-x-4">
          <AlertCircle size={24} className="text-red-500" />
          <p className="text-red-700 font-medium">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-5xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold text-black mb-2 text-center">Material Requisition</h1>
          <h2 className="text-2xl font-semibold text-gray-700 mb-8 text-center">{project.Project_Name}</h2>
        </motion.div>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg border border-gray-200 p-8">
          {Object.entries(boqData).map(([section, items], sectionIndex) => (
            <motion.div
              key={section}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: sectionIndex * 0.1 }}
              className="mb-8"
            >
              <button
                type="button"
                onClick={() => toggleSection(section)}
                className="flex justify-between items-center w-full py-3 px-6 bg-gray-100 text-black rounded-lg transition-all duration-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <h3 className="text-xl font-semibold">{section}</h3>
                {openSections[section] ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
              </button>
              <AnimatePresence>
                {openSections[section] && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <table className="min-w-full divide-y divide-gray-200 mt-4">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Available</th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Request</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {items.map((item, index) => (
                          <tr key={`${section}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.product}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.quantity}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input
                                type="number"
                                value={requisitionData[`${section}-${index}`] || 0}
                                onChange={(e) => handleQuantityChange(section, index, e.target.value)}
                                min="0"
                                max={Number(item.quantity)}
                                className="block w-full h-10 px-4 py-2 text-base rounded-md border-2 border-gray-300 focus:border-black focus:ring focus:ring-gray-200 focus:ring-opacity-50 transition-colors duration-200"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
          <motion.div
            className="mt-8 flex justify-end"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <button
              type="submit"
              disabled={submitting}
              className={`
                bg-black text-white font-bold py-3 px-8
                rounded-lg shadow-md hover:bg-gray-800
                transition-all duration-300 ease-in-out
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                ${submitting ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            >
              {submitting ? (
                <Loader2 size={24} className="animate-spin" />
              ) : (
                'Submit Requisition'
              )}
            </button>
          </motion.div>
        </form>
      </div>
    </div>
  );
}