import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const FireSprinklerSystem = ({ sprinklers = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = sprinklers.some(sprinkler => sprinkler.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Fire Sprinkler System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                <th className="p-3 text-sm font-semibold text-left">Application Area</th>
                <th className="p-3 text-sm font-semibold text-left">Length of area</th>
                <th className="p-3 text-sm font-semibold text-left">Width of area</th>
                <th className="p-3 text-sm font-semibold text-left">Is Design as per NBC Standards?</th>
                <th className="p-3 text-sm font-semibold text-left">Num of Sprinkler Riser</th>
                <th className="p-3 text-sm font-semibold text-left">Num of Total Sprinkler</th>
                <th className="p-3 text-sm font-semibold text-left">Is Alarm Valve Installed?</th>
                <th className="p-3 text-sm font-semibold text-left">Is System pressurized?</th>
                <th className="p-3 text-sm font-semibold text-left">Is Integration Done with Fire Alarm System?</th>
                <th className="p-3 text-sm font-semibold text-left">Overall Working Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {sprinklers.map((sprinkler, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{sprinkler.Application_Area || sprinkler.Other_Area || '---'}</td>
                  <td className="p-3 text-sm border">{sprinkler.Length_Of_Area ? `${sprinkler.Length_Of_Area} m` : '---'}</td>
                  <td className="p-3 text-sm border">{sprinkler.Width_Of_Area ? `${sprinkler.Width_Of_Area} m` : '---'}</td>
                  <td className="p-3 text-sm border">
                    {sprinkler.Design_As_Per_NBC === 'No' 
                      ? sprinkler.Design_Reason || sprinkler.Other_Reason 
                      : sprinkler.Design_As_Per_NBC}
                  </td>
                  <td className="p-3 text-sm border">{sprinkler.No_Of_Risers || '---'}</td>
                  <td className="p-3 text-sm border">{sprinkler.No_Of_Total_Sprinklers || '---'}</td>
                  <td className="p-3 text-sm border">
                    {sprinkler.Alarm_Valve_In === 'Yes' 
                      ? `${sprinkler.No_Of_Valves}\n${sprinkler.Valve_Working}` 
                      : sprinkler.Alarm_Valve_In}
                  </td>
                  <td className="p-3 text-sm border">
                    {sprinkler.System_Pressure_Status === 'Yes' 
                      ? sprinkler.Pressure 
                      : sprinkler.System_Pressure_Status}
                  </td>
                  <td className="p-3 text-sm border">
                    {sprinkler.Int_With_Fas === 'Yes' 
                      ? sprinkler.Int_With_Fas_Remarks 
                      : sprinkler.Int_With_Fas}
                  </td>
                  <td className="p-3 text-sm border">
                    {sprinkler.Working_Cond === 'No' ? sprinkler.Remarks : '---'}
                  </td>
                  <td className="p-3 text-sm border">{sprinkler.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations of Fire Sprinklers" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">Application Area</th>
                    <th className="p-3 text-sm font-semibold text-left">Length of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Width of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Height of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {sprinklers
                    .filter(sprinkler => sprinkler.New_System_Rec === 'Yes')
                    .flatMap((sprinkler, sprinklerIndex) => {
                      const newSystems = Array.isArray(sprinkler.New_Systems) ? sprinkler.New_Systems : [sprinkler.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${sprinklerIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{sprinklerIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Application_Area || newSystem?.Other_Area || 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Length_Of_Premise ? `${newSystem.Length_Of_Premise} m` : 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Width_Of_Premise ? `${newSystem.Width_Of_Premise} m` : 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Height_Of_Premise ? `${newSystem.Height_Of_Premise} m` : 'NA'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || 'NA'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FireSprinklerSystem;