import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import NavbarSidebar from './NavbarSidebar';

export default function Layout({ setIsLoggedIn }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen ">
      <NavbarSidebar
        setIsLoggedIn={setIsLoggedIn}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div
        className={`flex-1 flex flex-col overflow-hidden ${
          !isSmallScreen && isSidebarOpen ? 'ml-64' : !isSmallScreen && !isSidebarOpen ? 'ml-20' : ''
        }`}
      >
        <main className="flex-1 overflow-x-hidden overflow-y-auto pt-16">
          <div className="container mx-auto px-6 py-8">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}