import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomerDetails from '../CommonFormComponents/customerDetails';
import Letterhead from '../CommonFormComponents/letterHead/letterHead';
import DownloadButton from '../CommonFormComponents/downloadButton';
import Heading from '../CommonFormComponents/Heading';

const FireHydrants = () => {
  const location = useLocation();
  const { survey } = location.state || {};
  const fireHydrant = survey.system;

  if (!fireHydrant) {
    return <div className="text-center text-red-500">No Fire Hydrant data available.</div>;
  }

  const headers = [
    'Sr No.', 'Location', 'Water Pressure', 'Latch', 'Gland Nut', 'Hyd. Cap', 'Coupling Washer',
    'Short Branch Pipe', 'Rubber Beending', 'Key', 'Key Glass', 'Hose Box Glass', 'Hose box Condition',
    'Hose Pipe', 'Hose Box', 'Hammer', 'Remark'
  ];

  const rows = fireHydrant.hydrants.map((hydrant, index) => [
    index + 1,
    hydrant.Location || 'NA',
    hydrant.Water_Pressure || 'NA',
    hydrant.Latch || 'NA',
    hydrant.Gland_Nut || 'NA',
    hydrant.Hydrant_Cap || 'NA',
    hydrant.Coupling_Washer || 'NA',
    hydrant.Short_Branch_Pipe || 'NA',
    hydrant.Rubber_Bending || 'NA',
    hydrant.Key || 'NA',
    hydrant.Key_Glass || 'NA',
    hydrant.Hose_Box_Glass || 'NA',
    hydrant.Hose_Condition || 'NA',
    hydrant.Hose_Pipe || 'NA',
    hydrant.Hose_Box || 'NA',
    hydrant.Hammer || 'NA',
    hydrant.Remark || 'NA'
  ]);

  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50 shadow-lg rounded-lg">
      <Letterhead />

      <Heading title="Survey Report" />

      <CustomerDetails survey={survey} />

      <Heading title="Fire Hydrant System" />

      <div className="overflow-x-auto shadow-md rounded-lg mb-6">
        <table className="w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="p-3 text-sm font-semibold text-left">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} className="p-3 text-sm border">
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-gray-100 p-4 rounded-lg mb-6">
        <h4 className="text-lg font-semibold mb-2">Overall Remark:</h4>
        <p>{fireHydrant.remark || 'NA'}</p>
      </div>

      <div className="flex justify-center">
        <DownloadButton 
          reportId={survey.report_id} 
          clientName={survey.customerDetails?.client_name} 
          type="amc" 
        />
      </div>
    </div>
  );
};

export default FireHydrants;