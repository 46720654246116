import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Trash2, Loader } from 'lucide-react';

const unitOptions = ['Nos', 'Kg', 'Ltr', 'Mtr'];

const FormA = ({reportId}) => {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [date, setDate] = useState('');
  const [equipments, setEquipments] = useState([{ id: Date.now(), Equipment: '', Quantity: '', Unit: '', Remarks: 'Installation Done' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFetchingData, setIsFetchingData] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (reportId) {
      fetchExistingData();
    }
  }, [reportId]);

  useEffect(() => {
    const fetchClients = async () => {
      if (searchTerm.length > 2) {
        try {
          // const response = await axios.get(`${API_URL}/api/clients/list?search=${searchTerm}`);
          // setClients(response.data);
        } catch (error) {
          console.error('Error fetching clients:', error);
        }
      } else {
        setClients([]);
      }
    };

    fetchClients();
  }, [searchTerm, API_URL]);

  const fetchExistingData = async () => {
    setIsFetchingData(true);
    try {
      const response = await axios.get(`${API_URL}/api/documents/${reportId}`);
      const { Client_Name, Client_Address, Date, Equipments } = response.data;
      setSelectedClient(Client_Name);
      setSearchTerm(Client_Name);
      setClientAddress(Client_Address);
      setDate(formatDateForInput(Date));
      setEquipments(Equipments.map(eq => ({ ...eq, id: Math.random() })));
    } catch (error) {
      console.error('Error fetching existing data:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsFetchingData(false);
    }
  };

  const handleAddEquipment = () => {
    setEquipments([...equipments, { id: Date.now(), Equipment: '', Quantity: '', Unit: '', Remarks: 'Installation Done' }]);
  };

  const handleEquipmentChange = (id, field, value) => {
    setEquipments(equipments.map(eq => eq.id === id ? { ...eq, [field]: value } : eq));
  };

  const handleDeleteEquipment = (id) => {
    setEquipments(equipments.filter(eq => eq.id !== id));
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = {
      Client_Name: selectedClient || searchTerm, // Use searchTerm if no client is selected
      Client_Address: clientAddress,
      Date: formatDate(date) || undefined,
      Equipments: equipments.filter(eq => eq.Equipment && eq.Quantity && eq.Unit)
    };

    // If reportId is available, include it in the formData
    if (reportId) {
      formData.report_id = reportId;
    }

    try {
      const res = await axios.post(`${API_URL}/api/documents/formA`, {
        formData
      });
    
      if(res.status === 200) {
        console.log('Form A submitted successfully');
        navigate('/documents');
      }
    } catch (err) {
      console.error('Error submitting Form A:', err);
      // Add error handling here
    } finally {
      setIsLoading(false);
    }
  };

  if (isFetchingData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin h-8 w-8 text-indigo-600" />
      </div>
    );
  }

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg"
    >
      <h1 className="text-3xl font-bold text-center mb-8 text-indigo-700">
        {reportId ? 'Edit Form A' : 'Form A'}
      </h1>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="flex flex-wrap -mx-2 px-2">
          <div className="w-full md:w-3/4 px-2 mb-4 md:mb-0">
            <label htmlFor="client" className="block text-sm font-medium text-gray-700 mb-1">Client</label>
            <div className="relative">
              <input
                id="client"
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setSelectedClient('');
                }}
                placeholder="Search for a client or enter new client name"
                required
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
              <AnimatePresence>
                {clients.length > 0 && (
                  <motion.ul
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto shadow-lg"
                  >
                    {clients.map((client, index) => (
                      <motion.li
                        key={index}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: index * 0.05 }}
                        onClick={() => {
                          setSelectedClient(client.name);
                          setSearchTerm(client.name);
                          setClients([]);
                        }}
                        className="p-2 hover:bg-indigo-50 cursor-pointer transition-colors duration-150 ease-in-out"
                      >
                        {client.name}
                      </motion.li>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </div>
          </div>

          <div className="w-full md:w-1/4 px-2">
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">Date (Optional)</label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        <div className="w-full px-2">
          <label htmlFor="clientAddress" className="block text-sm font-medium text-gray-700 mb-1">Client Address</label>
          <input
            type="text"
            id="clientAddress"
            value={clientAddress}
            onChange={(e) => setClientAddress(e.target.value)}
            placeholder="Enter client address"
            required
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <AnimatePresence>
          {equipments.map((eq) => (
            <motion.div
              key={eq.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="flex flex-wrap mx-2 items-end bg-gray-50 p-4 rounded-lg relative"
            >
              <div className="w-full sm:w-1/3 px-2 mb-4 sm:mb-0">
                <label htmlFor={`equipment-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-1">Equipment</label>
                <input
                  id={`equipment-${eq.id}`}
                  type="text"
                  value={eq.Equipment}
                  onChange={(e) => handleEquipmentChange(eq.id, 'Equipment', e.target.value)}
                  placeholder="Equipment"
                  required
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full sm:w-1/6 px-2 mb-4 sm:mb-0">
                <label htmlFor={`quantity-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-1">Quantity</label>
                <input
                  id={`quantity-${eq.id}`}
                  type="number"
                  value={eq.Quantity}
                  onChange={(e) => handleEquipmentChange(eq.id, 'Quantity', e.target.value)}
                  placeholder="Quantity"
                  required
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full sm:w-1/6 px-2 mb-4 sm:mb-0">
                <label htmlFor={`unit-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-1">Unit</label>
                <select
                  id={`unit-${eq.id}`}
                  value={eq.Unit}
                  onChange={(e) => handleEquipmentChange(eq.id, 'Unit', e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select Unit</option>
                  {unitOptions.map((unit) => (
                    <option key={unit} value={unit}>{unit}</option>
                  ))}
                </select>
              </div>
              <div className="w-full sm:w-1/4 px-2 mb-4 sm:mb-0">
                <label htmlFor={`remarks-${eq.id}`} className="block text-sm font-medium text-gray-700 mb-1">Remarks</label>
                <input
                  id={`remarks-${eq.id}`}
                  type="text"
                  value={eq.Remarks}
                  onChange={(e) => handleEquipmentChange(eq.id, 'Remarks', e.target.value)}
                  placeholder="Remarks"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div className="w-full sm:w-auto px-2 flex items-center justify-end">
                <motion.button
                  type="button"
                  onClick={() => handleDeleteEquipment(eq.id)}
                  className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-colors duration-200"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Trash2 size={20} />
                </motion.button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        <div className="flex justify-center space-x-4">
          <motion.button
            type="button"
            onClick={handleAddEquipment}
            className="w-1/4 p-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Plus size={20} className="mr-2" />
            Add Equipment
          </motion.button>

          <motion.button
            type="submit"
            disabled={isLoading}
            className={`w-1/4 p-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors duration-200 flex items-center justify-center ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {isLoading ? (
              <Loader className="animate-spin h-5 w-5 mr-3" />
            ) : null}
            {isLoading ? 'Submitting...' : (reportId ? 'Update Form' : 'Submit Form')}
          </motion.button>
        </div>
      </form>
    </motion.div>
  );
};

export default FormA;