import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { jwtDecode } from 'jwt-decode';
import { FileText, Clipboard, BarChart2, FileSpreadsheet, ChevronDown, ChevronUp, Pen } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

export default function ProjectDetails() {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isManager, setIsManager] = useState(false);
  const [isOnSiteEngineer, setIsOnSiteEngineer] = useState(false);
  const [showAllBuyerContacts, setShowAllBuyerContacts] = useState(false);
  const [showAllConsigneeContacts, setShowAllConsigneeContacts] = useState(false);
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjectDetails();
    checkUserRole();
  }, [projectId]);

  const fetchProjectDetails = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_URL}/api/project/${projectId}`);
      setProject(response.data.project);
    } catch (err) {
      setError('Failed to fetch project details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const checkUserRole = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setIsManager(decodedToken.role === 'Manager');
        setIsOnSiteEngineer(decodedToken.role === 'On-Site Engineer');
      } catch (error) {
        console.error('Error decoding token:', error);
        setIsManager(false);
        setIsOnSiteEngineer(false);
      }
    }
  };

  const handleFileOpen = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  const handleEditProject = () => {
    navigate('/project/project-initialization', { state: { project } });
  };

  const handleFillBOQ = () => {
    navigate('/boq', { state: { project } });
  };

  const handleBOQOpen = () => {
    navigate(`/boq/${project.project_id}`, { state: { project } });
  };

  const handleMaterialRequisition = () => {
    navigate(`/materialRequisition/${project.project_id}`, { state: { project } });
  };

  const handleMaterialRequisitionSheet = () => {
    navigate(`/materialRequisitionSheet/${project.project_id}`, { state: { project } });
  };

  const handleProjectTrackingSheet = () => {
    navigate(`/projectTrackingSheet/${project.project_id}`, { state: { project } });
  };

  const renderContacts = (contacts, showAll, setShowAll) => {
    const displayedContacts = showAll ? contacts : contacts.slice(0, 3);
    return (
      <div className="space-y-4">
        {displayedContacts.map((contact, index) => (
          <div key={index} className="bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold">{contact.Name}</h4>
            <p>{contact.Designation}</p>
            <p>{contact.Email}</p>
            <p>{contact.Phone}</p>
          </div>
        ))}
        {contacts.length > 3 && (
          <button
            onClick={() => setShowAll(!showAll)}
            className="flex items-center text-primary hover:text-primary-dark"
          >
            {showAll ? (
              <>
                <ChevronUp className="mr-1" />
                Show Less
              </>
            ) : (
              <>
                <ChevronDown className="mr-1" />
                Show More
              </>
            )}
          </button>
        )}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <motion.div
          className="w-16 h-16 border-4 border-primary border-t-transparent rounded-full"
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-red-100 text-red-700 p-4 rounded-lg shadow-lg"
        >
          {error}
        </motion.div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        <div className="bg-white shadow-2xl rounded-3xl overflow-hidden">
          <div className="bg-gradient-to-r from-primary to-primary-dark p-8 text-black">
            <motion.div
              initial={{ opacity: 0, y:-20 }}
              animate={{ opacity: 1, y:0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              className="space-y-6"
            >
              <div className="flex justify-between items-center">
                <h1 className="text-4xl font-bold mb-2">{project.Project_Name}</h1>
                {isManager && (
                  <button
                    onClick={handleEditProject}
                    className="bg-white text-primary font-bold py-2 px-4 rounded-full shadow-lg hover:bg-gray-100 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 flex items-center"
                  >
                    <Pen className="mr-2" size={18} />
                    Edit Project
                  </button>
                )}
              </div>
              <p className="text-xl opacity-90">Project ID: {project.project_id}</p>
            </motion.div>
          </div>
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="space-y-6"
              >
                <h2 className="text-2xl font-semibold text-gray-800 border-b-2 border-primary pb-2">Buyer Information</h2>
                <div className="space-y-4">
                  {Object.entries(project.Buyer).map(([key, value]) => (
                    key !== 'Contacts' && (
                      <div key={key} className="flex items-center">
                        <span className="w-1/3 text-gray-600 font-medium">{key.replace('_', ' ')}:</span>
                        <span className="w-2/3 text-gray-800">{value}</span>
                      </div>
                    )
                  ))}
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mt-4">Contacts</h3>
                {renderContacts(project.Buyer.Contacts, showAllBuyerContacts, setShowAllBuyerContacts)}
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className="space-y-6"
              >
                <h2 className="text-2xl font-semibold text-gray-800 border-b-2 border-primary pb-2">Consignee Information</h2>
                <div className="space-y-4">
                  {Object.entries(project.Consignee).map(([key, value]) => (
                    key !== 'Contacts' && (
                      <div key={key} className="flex items-center">
                        <span className="w-1/3 text-gray-600 font-medium">{key.replace('_', ' ')}:</span>
                        <span className="w-2/3 text-gray-800">{value}</span>
                      </div>
                    )
                  ))}
                </div>
                <h3 className="text-xl font-semibold text-gray-800 mt-4">Contacts</h3>
                {renderContacts(project.Consignee.Contacts, showAllConsigneeContacts, setShowAllConsigneeContacts)}
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="mt-12 space-y-8"
            >
              <h2 className="text-2xl font-semibold text-gray-800 border-b-2 border-primary pb-2">Project Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex items-center">
                  <span className="text-gray-600 font-medium mr-2">Initialized:</span>
                  <span className="text-gray-800">{project.date}</span>
                </div>
                <div className="flex items-center">
                  <span className="text-gray-600 font-medium mr-2">Status:</span>
                  <span className="text-gray-800">{project.Status}</span>
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Scope of Work</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {project.Selected_Products.map((product, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 0.1 * index, duration: 0.3 }}
                      className="bg-white border border-primary rounded-lg shadow-md overflow-hidden"
                    >
                      <div className="bg-primary-light p-3">
                        <h4 className="text-primary font-semibold truncate">{product}</h4>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="mt-12 flex flex-wrap justify-center gap-4"
            >
              {project.Uploaded_File && (
                <button
                  onClick={() => handleFileOpen(project.Uploaded_File)}
                  className="bg-black text-white font-bold py-4 px-8 rounded-full shadow-lg hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 flex items-center"
                >
                  <FileText className="mr-2" />
                  View PO
                </button>
              )}
              <button
                onClick={handleBOQOpen}
                className="bg-black text-white font-bold py-4 px-8 rounded-full shadow-lg hover:bg-gray-800 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 flex items-center"
              >
                <Clipboard className="mr-2" />
                View BOQ
              </button>
              {isManager && (
                <button
                  onClick={handleFillBOQ}
                  className="bg-black text-white font-bold py-4 px-8 rounded-full shadow-lg hover:bg-primary-dark transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 flex items-center"
                >
                  <BarChart2 className="mr-2" />
                  Fill BOQ
                </button>
              )}
              {isOnSiteEngineer && (
                <button
                  onClick={handleMaterialRequisition}
                  className="bg-green-600 text-white font-bold py-4 px-8 rounded-full shadow-lg hover:bg-green-700 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 flex items-center"
                >
                  <Clipboard className="mr-2" />
                  Material Requisition
                </button>
              )}
              <button
                onClick={handleMaterialRequisitionSheet}
                className="bg-blue-600 text-white font-bold py-4 px-8 rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 flex items-center"
              >
                <FileSpreadsheet className="mr-2" />
                Material Requisition Sheet
              </button>
              {isManager && (
                <button
                  onClick={handleProjectTrackingSheet}
                  className="bg-purple-600 text-white font-bold py-4 px-8 rounded-full shadow-lg hover:bg-purple-700 transition-all duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50 flex items-center"
                >
                  <FileSpreadsheet className="mr-2" />
                  Project Tracking Sheet
                </button>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}