import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Trash2 } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const BOQItem = ({ item, onUpdate, onDelete, showDelete }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="flex items-center space-x-4 p-4 bg-white rounded-lg shadow-md"
        >
            <input
                className="flex-1 p-2 border rounded"
                placeholder="Product"
                value={item.product}
                onChange={(e) => onUpdate({ ...item, product: e.target.value })}
            />
            <input
                className="w-24 p-2 border rounded"
                placeholder="Unit"
                value={item.unit}
                onChange={(e) => onUpdate({ ...item, unit: e.target.value })}
            />
            <input
                className="w-24 p-2 border rounded"
                type="number"
                placeholder="Quantity"
                value={item.quantity}
                onChange={(e) => onUpdate({ ...item, quantity: e.target.value })}
            />
            <input
                className="flex-1 p-2 border rounded"
                placeholder="Make"
                value={item.make}
                onChange={(e) => onUpdate({ ...item, make: e.target.value })}
            />
            {showDelete && (
                <button
                    onClick={onDelete}
                    className="p-2 text-red-600 hover:text-red-800 transition-colors duration-200"
                >
                    <Trash2 size={20} />
                </button>
            )}
        </motion.div>
    );
};

const BOQSection = ({ section, items, onAddItem, onUpdateItem, onDeleteItem, isOpen, onToggle }) => {
    return (
        <div className="mb-6 bg-white rounded-lg overflow-hidden shadow-lg border border-gray-200">
            <button
                className="w-full p-4 text-left font-semibold text-lg bg-white text-black hover:bg-gray-100 transition-colors duration-200 flex justify-between items-center"
                onClick={onToggle}
            >
                <span>{section}</span>
                <span>{isOpen ? '▲' : '▼'}</span>
            </button>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="p-4 space-y-4">
                            {items.map((item, index) => (
                                <BOQItem
                                    key={index}
                                    item={item}
                                    onUpdate={(updatedItem) => onUpdateItem(section, index, updatedItem)}
                                    onDelete={() => onDeleteItem(section, index)}
                                    showDelete={items.length > 1}
                                />
                            ))}
                            <div className="flex justify-center">
                                <button
                                    onClick={() => onAddItem(section)}
                                    className="flex items-center justify-center w-1/6 p-2 bg-black text-white rounded hover:bg-gray-800 transition-colors duration-200"
                                >
                                    <Plus size={20} className="mr-2" /> Add Item
                                </button>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const BOQ = () => {
    const [boqData, setBoqData] = useState({});
    const [openSections, setOpenSections] = useState({});
    const location = useLocation();
    const { project } = location.state || {};

    useEffect(() => {
        const fetchBOQData = async () => {
            if (!project || !project.project_id) {
                console.error('Project data is missing or invalid');
                return;
            }

            try {
                const response = await axios.get(`${API_URL}/api/projects/boq/${project.project_id}`);
                if (response.data) {
                    setBoqData(response.data.project.boq);
                    console.log(response.data.project.boq);
                } else {
                    const initialBOQ = project.Selected_Products.reduce((acc, product) => {
                        acc[product] = [{ product: '', unit: '', quantity: '', make: '' }];
                        return acc;
                    }, {});
                    setBoqData(initialBOQ);
                }
                setOpenSections(project.Selected_Products.reduce((acc, product) => {
                    acc[product] = false;
                    return acc;
                }, {}));
            } catch (error) {
                console.error('Error fetching BOQ data:', error);
            }
        };

        fetchBOQData();
    }, [project]);

    const handleAddItem = (section) => {
        setBoqData(prevData => ({
            ...prevData,
            [section]: [...(prevData[section] || []), { product: '', unit: '', quantity: '', make: '' }]
        }));
    };

    const handleUpdateItem = (section, index, updatedItem) => {
        setBoqData(prevData => ({
            ...prevData,
            [section]: prevData[section].map((item, i) => i === index ? updatedItem : item)
        }));
    };

    const handleDeleteItem = (section, index) => {
        setBoqData(prevData => ({
            ...prevData,
            [section]: prevData[section].filter((_, i) => i !== index)
        }));
    };

    const handleToggleSection = (section) => {
        setOpenSections(prevState => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const handleSubmit = async () => {
        if (!project || !project.project_id) {
            console.error('Project data is missing or invalid');
            return;
        }

        const formData = {
            project_id: project.project_id,
            boq: boqData
        };

        try {
            const res = await axios.post(`${API_URL}/api/projects/boq`, { 
                    formData
                },
            );
            if(res.status === 200) {
                alert('BOQ submitted successfully');
            }
        } catch (error) {
            console.error('Error submitting BOQ:', error);
            alert('Failed to submit BOQ. Please try again.');
        }
    };

    if (!project) {
        return <div className="text-center text-red-600 mt-8">Error: Project data not found</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Bill of Quantities</h1>
            <h2 className="text-xl font-semibold mb-4 text-center text-gray-600">{project.Project_Name}</h2>
            {project.Selected_Products.map(product => (
                <BOQSection
                    key={product}
                    section={product}
                    items={boqData[product] || []}
                    onAddItem={handleAddItem}
                    onUpdateItem={handleUpdateItem}
                    onDeleteItem={handleDeleteItem}
                    isOpen={openSections[product]}
                    onToggle={() => handleToggleSection(product)}
                />
            ))}
            <div className="mt-8 flex justify-center">
                <button
                    onClick={handleSubmit}
                    className="px-6 py-3 bg-black text-white rounded-full font-semibold hover:bg-gray-800 transition-colors duration-200 shadow-lg"
                >
                    Submit BOQ
                </button>
            </div>
        </div>
    );
};

export default BOQ;