import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const GasLeakDetectorSystem = ({ gases = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = gases.some(gas => gas.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Gas Leak Detector System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                <th className="p-3 text-sm font-semibold text-left">Type of Gas</th>
                <th className="p-3 text-sm font-semibold text-left">Make of the System</th>
                <th className="p-3 text-sm font-semibold text-left">Num of Gas Leak Detectors</th>
                <th className="p-3 text-sm font-semibold text-left">Integration with Gas Shut Off Valve</th>
                <th className="p-3 text-sm font-semibold text-left">Overall Working Condition of System</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {gases.map((gas, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{gas.Gas_Type || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Make || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Leak_Det || '---'}</td>
                  <td className="p-3 text-sm border">{gas.Int_With_Valve === 'Yes' ? gas.Int_With_Valve_Remarks || 'NA' : '---'}</td>
                  <td className="p-3 text-sm border">{gas.Working_Cond === 'No' ? gas.Remark || 'NA' : '---'}</td>
                  <td className="p-3 text-sm border">{gas.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations of Gas Leak Detector System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">Gas to be Detected</th>
                    <th className="p-3 text-sm font-semibold text-left">No. of Area Covered</th>
                    <th className="p-3 text-sm font-semibold text-left">Application Area</th>
                    <th className="p-3 text-sm font-semibold text-left">Length of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Width of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Height of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Is Shut off Valve Required?</th>
                  </tr>
                </thead>
                <tbody>
                  {gases
                    .filter(gas => gas.New_System_Rec === 'Yes')
                    .flatMap((gas, gasIndex) => {
                      const newSystems = Array.isArray(gas.New_Systems) ? gas.New_Systems : [gas.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${gasIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{gasIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Gas_Detected || newSystem?.Other_Area || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.No_Of_Area || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Application_Area || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Length_Of_Premise || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Width_Of_Premise || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Height_Of_Premise || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Shut_Off_Valve === 'No' ? newSystem.Remark : '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GasLeakDetectorSystem;