import React, { useState } from 'react';
import CollapsibleHeading from '../../CommonFormComponents/CollapsibleHeading';
import RenderFiles from '../RenderFiles';

const RodentRepellentSystem = ({ rodents = [], files = [] }) => {
  const [isMainExpanded, setIsMainExpanded] = useState(true);
  const [isNewSystemExpanded, setIsNewSystemExpanded] = useState(false);

  const newSystemExists = rodents.some(rodent => rodent.New_System_Rec === 'Yes');

  const toggleMain = () => setIsMainExpanded(!isMainExpanded);
  const toggleNewSystem = () => setIsNewSystemExpanded(!isNewSystemExpanded);

  return (
    <div className="space-y-4">
      <CollapsibleHeading 
        title="Rodent Repellent System" 
        isExpanded={isMainExpanded} 
        onToggle={toggleMain}
      />
      <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isMainExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
        <div className="overflow-x-auto border rounded-lg shadow-sm">
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="p-3 text-sm font-semibold text-left">Sr. No.</th>
                <th className="p-3 text-sm font-semibold text-left">Panel Make</th>
                <th className="p-3 text-sm font-semibold text-left">Working Condition</th>
                <th className="p-3 text-sm font-semibold text-left">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {rodents.map((rodent, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                  <td className="p-3 text-sm border">{index + 1}</td>
                  <td className="p-3 text-sm border">{rodent.Make || rodent.Other_Area || '---'}</td>
                  <td className="p-3 text-sm border">{rodent.Working_Cond === 'No' ? rodent.Remark : rodent.Working_Cond}</td>
                  <td className="p-3 text-sm border">{rodent.Write_Remarks || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {files.length > 0 && <RenderFiles files={files} />}
      </div>

      {newSystemExists && (
        <>
          <CollapsibleHeading 
            title="New Recommendations for Rodent Suppression System" 
            isExpanded={isNewSystemExpanded} 
            onToggle={toggleNewSystem}
          />
          <div className={`overflow-hidden transition-all duration-300 ease-in-out ${isNewSystemExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}>
            <div className="overflow-x-auto border rounded-lg shadow-sm">
              <table className="w-full bg-white">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 text-sm font-semibold text-left">SrNo</th>
                    <th className="p-3 text-sm font-semibold text-left">Location</th>
                    <th className="p-3 text-sm font-semibold text-left">Length of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Width of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Height of Premise</th>
                    <th className="p-3 text-sm font-semibold text-left">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {rodents
                    .filter(rodent => rodent.New_System_Rec === 'Yes')
                    .flatMap((rodent, rodentIndex) => {
                      const newSystems = Array.isArray(rodent.New_Systems) ? rodent.New_Systems : [rodent.New_Systems];
                      return newSystems.map((newSystem, index) => (
                        <tr key={`${rodentIndex}-${index}`} className="hover:bg-gray-50 transition-colors duration-150">
                          <td className="p-3 text-sm border">{rodentIndex + index + 1}</td>
                          <td className="p-3 text-sm border">{newSystem?.Location || '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Length_Of_Premise ? `${newSystem.Length_Of_Premise} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Width_Of_Premise ? `${newSystem.Width_Of_Premise} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Height_Of_Premise ? `${newSystem.Height_Of_Premise} mm` : '---'}</td>
                          <td className="p-3 text-sm border">{newSystem?.Write_Remarks || '---'}</td>
                        </tr>
                      ));
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RodentRepellentSystem;