import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeft, ChevronRight, Search, Calendar, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

// You'll need to create this component separately
import LoadingSkeleton from './CommonFormComponents/LoadingSkeleton';

const ProductsSurveyed = ({ survey }) => {
  const products = [
    { key: 'fireExtinguisher', name: 'Fire Extinguisher', condition: survey => survey.fireExtinguisher?.extinguishers?.length > 0 },
    { key: 'fireHydrantSystem', name: 'Fire Hydrant System', condition: survey => survey.fireHydrantSystem?.hydrants?.length > 0 },
    { key: 'hoseReel', name: 'Fire Hose Reel', condition: survey => survey.hoseReel?.reels?.length > 0 },
    { key: 'fireAlarmDetectionSystem', name: 'Fire Alarm Detection System', condition: survey => survey.fireAlarmDetectionSystem?.alarms?.length > 0 },
    { key: 'firePumpRoom', name: 'Fire Pump Room', condition: survey => survey.firePumpRoom?.pumps?.length > 0 },
    { key: 'fireSprinklerSystem', name: 'Fire Sprinkler System', condition: survey => survey.fireSprinklerSystem?.sprinklers?.length > 0 },
    { key: 'gasLeakDetectorSystem', name: 'Gas Leak Detector System', condition: survey => survey.gasLeakDetectorSystem?.gases?.length > 0 },
    { key: 'publicAddressSystem', name: 'Public Address System', condition: survey => survey.publicAddressSystem?.addresses?.length > 0 },
    { key: 'rodentRepellentSystem', name: 'Rodent Repellent System', condition: survey => survey.rodentRepellentSystem?.rodents?.length > 0 },
    { key: 'waterLeakDetectorSystem', name: 'Water Leak Detector System', condition: survey => survey.waterLeakDetectorSystem?.devices?.length > 0 },
    { key: 'kitchenSuppressionSystem', name: 'Kitchen Suppression System', condition: survey => survey.kitchenSuppressionSystem?.kitchens?.length > 0 },
    { key: 'roomSuppressionSystem', name: 'Room Suppression System', condition: survey => survey.roomSuppressionSystem?.rooms?.length > 0 },
    { key: 'tubeSuppressionSystem', name: 'Tube Suppression System', condition: survey => survey.tubeSuppressionSystem?.tubes?.length > 0 },
  ];

  return (
    <div className="mt-4">
      <span className="text-sm font-semibold text-black">Products Surveyed:</span>
      <div className="flex flex-wrap gap-2 mt-2">
        {products.map((product) => (
          product.condition(survey) && (
            <span key={product.key} className="px-2 py-1 bg-blue-100 text-black text-xs rounded-full">
              {product.name}
            </span>
          )
        ))}
      </div>
    </div>
  );
};

export default function SurveyReports() {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState('All');
  const [startFilterDate, setStartFilterDate] = useState('');
  const [endFilterDate, setEndFilterDate] = useState('');
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchSurveys(selectedSurvey);
  }, [selectedSurvey, currentPage, startFilterDate, endFilterDate, searchQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const formatDateForServer = (inputDate) => {
    const [year, month, day] = inputDate.split('-');
    return `${day}-${month}-${year}`;
  }

  const fetchSurveys = async (surveyType) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
  
    const params = {
      surveysPerPage: 10,
      pageNumber: currentPage,
      type: surveyType !== 'all' ? surveyType : undefined,
      startDate: startFilterDate ? formatDateForServer(startFilterDate) : undefined,
      endDate: endFilterDate ? formatDateForServer(endFilterDate) : undefined,
      searchQuery: searchQuery ? searchQuery : undefined,
    };
  
    try {
      const response = await axios.get(`${API_URL}/api/reports`, {
        headers: {
          Authorization: `${token}`,
        },
        params,
      });

      setSurveys(response.data.reports);
      setTotalPages(response.data.totalPages);
      setTotalReports(response.data.totalReports);
      setFilteredSurveys(response.data.reports);

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setSurveys([]);
        setFilteredSurveys([]);
        setTotalPages(0);
        setTotalReports(0);
      } else if ((error.response && error.response.status === 403) || (error.response && error.response.status === 400)) {
        alert('Session expired. You need to log in again.');
        setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/');
        }, 3000);
      } else {
        console.error('Error fetching surveys:', error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSurveyTypeClick = (surveyType) => {
    setSelectedSurvey(surveyType);
    setCurrentPage(1);
  };

  const handleStartDateChange = (e) => {
    const inputDate = e.target.value;
    const today = new Date().toISOString().split('T')[0];
    
    if (inputDate <= today) {
      setStartFilterDate(inputDate);

      if (inputDate && endFilterDate && new Date(inputDate) > new Date(endFilterDate)) {
        setEndFilterDate(inputDate);
      }

      setCurrentPage(1);
    }
  };

  const handleEndDateChange = (e) => {
    const inputDate = e.target.value;
    setEndFilterDate(inputDate);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setSearchQuery(value);
    }
  };

  const clearFilter = () => {
    setStartFilterDate('');
    setEndFilterDate('');
    setSearchQuery('');
    setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);

    if (endPage - startPage < 4) {
      startPage = Math.max(endPage - 4, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handleSurveySelect = (reportId) => {
    const selectedSurveyData = surveys.find(survey => survey.report_id === reportId);

    if (selectedSurveyData && selectedSurveyData.type) {
      const surveyType = selectedSurveyData.type;

      if (surveyType === 'Basic_Survey') {
        navigate(`/baseSurveys/basicSurvey`, { state: { survey: selectedSurveyData } });
      } else if (surveyType === 'Detailed_Survey') {
        navigate(`/baseSurveys/detailedSurvey`, { state: { survey: selectedSurveyData } });
      } else if (surveyType === 'Form_B') {
        navigate(`/baseSurveys/formB`, { state: { survey: selectedSurveyData } });
      } else {
        console.warn('Unknown survey type:', surveyType);
      }
    } else {
      console.error('Survey type is missing or invalid for the selected report.');
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="min-h-screen p-8 bg-white text-black"
    >
      <div className="max-w-7xl mx-auto">
        <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-12 text-black"
        >
          Survey Reports
        </motion.h1>

        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="bg-gray-100 rounded-xl shadow-lg p-6 mb-8"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            {['all', 'Basic_Survey', 'Detailed_Survey', 'Form_B'].map((type, index) => (
              <motion.button
                key={type}
                onClick={() => handleSurveyTypeClick(type)}
                className={`px-4 py-3 rounded-lg transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
                  selectedSurvey === type
                    ? 'bg-black text-white shadow-md'
                    : 'bg-white text-black hover:bg-gray-200'
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
              >
                {type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
              </motion.button>
            ))}
          </div>

          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="flex flex-wrap items-center gap-4">
              <motion.div 
                className="relative"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <input
                  type="date"
                  value={startFilterDate}
                  onChange={handleStartDateChange}
                  className="border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-black"
                  max={new Date().toISOString().split('T')[0]}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </motion.div>
              <motion.div 
                className="relative"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <input
                  type="date"
                  value={endFilterDate}
                  onChange={handleEndDateChange}
                  className="border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-black"
                  max={new Date().toISOString().split('T')[0]}
                  min={startFilterDate}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </motion.div>
              <motion.div 
                className="relative flex-grow"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <input
                  type="text"
                  placeholder="Search by client or engineer name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-black"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </motion.div>
              <motion.button 
                onClick={clearFilter}
                className="px-4 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200 transition-colors duration-300 flex items-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <X size={18} className="mr-2" />
                Clear Filter
              </motion.button>
            </div>
            <motion.p 
              className="text-black font-semibold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              Total Reports: {totalReports}
            </motion.p>
          </div>
        </motion.div>

        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <AnimatePresence>
            <motion.div className="space-y-4">
              {filteredSurveys.map((survey, index) => (
                <motion.div 
                  key={survey.report_id} 
                  onClick={() => handleSurveySelect(survey.report_id)}
                  className="rounded-lg shadow-md p-6 cursor-pointer"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                  whileHover={{ 
                    scale: 1.02, 
                    boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    transition: { duration: 0.2 }
                  }}
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div>
                      <h3 className="text-lg font-semibold text-black">{survey.customerDetails?.client_name ?? 'Unknown Client'}</h3>
                      <p className="text-gray-600">Report ID: {survey.report_id}</p>
                    </div>
                    <div>
                      <p className="text-gray-600">Date: {survey.date}</p>
                      <p className="text-gray-600">Type: {survey.type?.replace('_', ' ') ?? 'Unknown'}</p>
                    </div>
                    <div>
                      <p className="text-gray-600">Supervisor: {survey.engineerName ?? 'No supervisor'}</p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <ProductsSurveyed survey={survey} />
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        )}

        <motion.div 
          className="flex justify-center items-center space-x-2 mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <motion.button
            
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-lg flex items-center transition-colors duration-300 ${
              currentPage === 1 
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                : 'bg-gray-200 text-black hover:bg-gray-300'
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <ChevronLeft size={18} className="mr-1" />
            Previous
          </motion.button>
          {getPageNumbers().map((pageNumber) => (
            <motion.button
              key={pageNumber}
              onClick={() => setCurrentPage(pageNumber)}
              className={`w-10 h-10 flex items-center justify-center rounded-lg transition-colors duration-300 ${
                currentPage === pageNumber
                  ? 'bg-black text-white'
                  : 'bg-gray-200 text-black hover:bg-gray-300'
              }`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              aria-label={`Page ${pageNumber}`}
              aria-current={currentPage === pageNumber ? 'page' : undefined}
            >
              {pageNumber}
            </motion.button>
          ))}
          <motion.button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-lg flex items-center transition-colors duration-300 ${
              currentPage === totalPages 
                ? 'bg-gray-200 text-gray-400 cursor-not-allowed' 
                : 'bg-gray-200 text-black hover:bg-gray-300'
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Next
            <ChevronRight size={18} className="ml-1" />
          </motion.button>
        </motion.div>
      </div>
    </motion.div>
  );
}