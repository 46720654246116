import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import PrivacyPolicy from './components/PrivacyPolicy';

import Profile from './components/Profile';

import Layout from './components/Layout';
import Login from './components/Login';
import SurveyReports from './components/SurveyReports';
import BasicSurvey from './components/SurveyForms/BasicSurvey';
import DetailedSurvey from './components/SurveyForms/detailedSurvey';
import FormB from './components/SurveyForms/formB';
import Dashboard from './components/Dashboard';

import FireAlarmSystem from './components/AMCs/FireAlarmSystem';
import FireExtinguishers from './components/AMCs/FireExtinguishers';
import FireHydrants from './components/AMCs/FireHydrants';
import FirePumpRoom from './components/AMCs/FirePumpRoom';
import HoseReel from './components/AMCs/HoseReel';

import AMC from './components/AMCs/AMC';
import AMCInitialization from './components/AMCs/AMCInitialization';
import AMCDetails from './components/AMCs/AMCDetails';

import DocumentsManager from './components/Documents';
import FillDocuments from './components/FillDocuments';

import Projects from './components/Projects/Projects';
import ProjectInitialization from './components/Projects/ProjectInitialization';
import ProjectDetails from './components/Projects/ProjectDetails';
import BOQ from './components/Projects/BOQ';
import BOQDisplay from './components/Projects/BOQDisplay';
import MaterialRequisition from './components/Projects/MaterialRequisition';
import MaterialRequisitionSheet from './components/Projects/MaterialRequisitionSheet';
import ProjectTrackingSheet from './components/Projects/ProjectTrackingSheet';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        
        <Route
          element={
            isLoggedIn ? (
              <Layout setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
            <Route path="/" element={<Navigate to="/dashboard" replace />} />

            <Route path="/baseSurveys" element={<SurveyReports />} />
            <Route path="/profile" element={<Profile />} />

            <Route path="/baseSurveys/basicSurvey" element={<BasicSurvey />} />
            <Route path="/baseSurveys/detailedSurvey" element={<DetailedSurvey />} />
            <Route path="/baseSurveys/formB" element={<FormB />} />

            <Route path="/amc" element={<AMC />} />
            <Route path="/amc/amc-contract" element={<AMCInitialization />} />
            <Route path="/amc/:amcId" element={<AMCDetails />} />
            <Route path="/Fire_Alarm" element={<FireAlarmSystem />} />
            <Route path="/Fire_Extinguisher" element={<FireExtinguishers />} />
            <Route path="/Fire_Hydrant" element={<FireHydrants />} />
            <Route path="/Fire_Pump_Room" element={<FirePumpRoom />} />
            <Route path="/Fire_Hose_Reel" element={<HoseReel />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/documents" element={<DocumentsManager />} />
            <Route path="/documents/fill-documents" element={<FillDocuments />} />

            <Route path="/project" element={<Projects />} />
            <Route path="/project/project-initialization" element={<ProjectInitialization />} />
            <Route path="/project/:projectId" element={<ProjectDetails />} />
            <Route path="/boq" element={<BOQ />} />
            <Route path="/boq/:projectId" element={<BOQDisplay />} />
            <Route path="/materialRequisition/:projectId" element={<MaterialRequisition />} />
            <Route path="/materialRequisitionSheet/:projectId" element={<MaterialRequisitionSheet />} />
            <Route path="/projectTrackingSheet/:projectId" element={<ProjectTrackingSheet />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;