import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Search, Calendar, X, Download, PlusCircle, ChevronRight, User, MapPin, ChevronLeft, CheckCircle, FileX } from 'lucide-react';

export default function DocumentsManager() {
  const [API_URL] = useState(process.env.REACT_APP_API_URL);
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState('All');
  const [startFilterDate, setStartFilterDate] = useState('');
  const [endFilterDate, setEndFilterDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchDocuments(selectedDocumentType);
  }, [selectedDocumentType, currentPage, startFilterDate, endFilterDate, searchQuery]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const fetchDocuments = async (documentType) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
  
    const params = {
      documentsPerPage: 12,
      pageNumber: currentPage,
      type: documentType !== 'All' ? documentType : undefined,
      startDate: startFilterDate ? formatDateForServer(startFilterDate) : undefined,
      endDate: endFilterDate ? formatDateForServer(endFilterDate) : undefined,
      searchQuery: searchQuery || undefined,
    };
  
    try {
      const response = await axios.get(`${API_URL}/api/documents`, {
        headers: { Authorization: token },
        params,
      });

      setDocuments(response.data.documents);
      setTotalPages(response.data.totalPages);
      setTotalDocuments(response.data.totalDocuments);
      
    } catch (error) {
      console.error('Error fetching documents:', error);
      setDocuments([]);
      setTotalPages(0);
      setTotalDocuments(0);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateForServer = (inputDate) => {
    const [year, month, day] = inputDate.split('-');
    return `${day}-${month}-${year}`;
  }

  const handleDocumentTypeClick = (documentType) => {
    setSelectedDocumentType(documentType);
    setCurrentPage(1);
  };

  const handleStartDateChange = (e) => {
    const inputDate = e.target.value;
    const today = new Date().toISOString().split('T')[0];
    
    if (inputDate <= today) {
      setStartFilterDate(inputDate);
      if (inputDate && endFilterDate && new Date(inputDate) > new Date(endFilterDate)) {
        setEndFilterDate(inputDate);
      }
      setCurrentPage(1);
    }
  };

  const handleEndDateChange = (e) => {
    setEndFilterDate(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setSearchQuery(value);
      setCurrentPage(1);
    }
  };

  const clearFilter = () => {
    setStartFilterDate('');
    setEndFilterDate('');
    setSearchQuery('');
    setCurrentPage(1);
  };

  const handleNewForm = () => {
    navigate('/documents/fill-documents');
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);

    if (endPage - startPage < 4) {
      startPage = Math.max(endPage - 4, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const DownloadButton = ({ reportId, clientName, type }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDownloadComplete, setIsDownloadComplete] = useState(false);

    const downloadPDF = async () => {
      setIsDownloading(true);
      try {
        const response = await axios.get(`${API_URL}/api/generate/documents/${type}/${reportId}`, {
          responseType: 'blob'
        });
  
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.download = `${reportId}_${clientName}.pdf`;
  
        document.body.appendChild(link);
        link.click();
        link.remove();

        setIsDownloadComplete(true);
        setTimeout(() => setIsDownloadComplete(false), 3000);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      } finally {
        setIsDownloading(false);
      }
    };
  
    return (
      <motion.button 
        className={`p-2 rounded-full transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 ${
          isDownloading ? 'bg-gray-300' : isDownloadComplete ? 'bg-green-500 text-white' : 'bg-primary text-primary-foreground hover:bg-primary/90'
        }`}
        onClick={downloadPDF}
        disabled={isDownloading}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {isDownloading ? (
          <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
        ) : isDownloadComplete ? (
          <CheckCircle size={18} />
        ) : (
          <Download size={18} />
        )}
      </motion.button>
    );
  };

  return (
    <div className="min-h-screen p-8">
      <div className="max-w-7xl mx-auto">
        <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-12 text-gray-800"
        >
          Documents Manager
        </motion.h1>

        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-xl shadow-lg p-6 mb-8"
        >
          <div className="flex justify-between items-center mb-6">
            <div className="flex space-x-4">
              {['All', 'Form_A', 'Form_B'].map((type, index) => (
                <motion.button
                  key={type}
                  onClick={() => handleDocumentTypeClick(type)}
                  className={`px-4 py-2 rounded-full transition-all duration-300 ${
                    selectedDocumentType === type
                      ? 'bg-black text-white shadow-md'
                      : 'bg-gray-100 text-gray-800 hover:bg-gray-300'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                </motion.button>
              ))}
            </div>
            <motion.button
              onClick={handleNewForm}
              className="mt-6 bg-black text-white px-6 py-3 rounded-full font-medium text-sm transition-all duration-300 ease-in-out hover:bg-gray-800 flex items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <PlusCircle size={18} className="mr-2" />
              Create New Document
            </motion.button>
          </div>

          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="flex flex-wrap items-center gap-4">
              <motion.div 
                className="relative"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <input
                  type="date"
                  value={startFilterDate}
                  onChange={handleStartDateChange}
                  className="border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-primary"
                  max={new Date().toISOString().split('T')[0]}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </motion.div>
              <motion.div 
                className="relative"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <input
                  type="date"
                  value={endFilterDate}
                  onChange={handleEndDateChange}
                  className="border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-primary"
                  max={new Date().toISOString().split('T')[0]}
                  min={startFilterDate}
                />
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </motion.div>
              <motion.div 
                className="relative flex-grow"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <input
                  type="text"
                  placeholder="Search by client or document name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full border rounded-lg px-4 py-2 pl-10 focus:outline-none focus:ring-2 focus:ring-primary"
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              </motion.div>
              <motion.button 
                onClick={clearFilter}
                className="px-4 py-2 bg-red-100 text-red-600 rounded-lg hover:bg-red-200 transition-colors duration-300 flex items-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <X size={18} className="mr-2" />
                Clear Filter
              </motion.button>
            </div>
            <motion.p 
              className="text-primary font-semibold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              Total Documents: {totalDocuments}
            </motion.p>
          </div>
        </motion.div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          </div>
        ) : documents.length > 0 ? (
          <AnimatePresence>
            <motion.div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {documents.map((document, index) => (
                <motion.div 
                  key={document.report_id} 
                  className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3, delay: index * 0.05 }}
                >
                  <div className="p-6 space-y-4">
                    <div className="flex justify-between items-start">
                      <h3 className="text-xl font-semibold text-primary truncate">{document.Client_Name ?? 'Unknown Client'}</h3>
                      <DownloadButton 
                        reportId={document.report_id} 
                        clientName={document.Client_Name} 
                        type={document.type} 
                      />
                    </div>
                    <div className="space-y-2 text-sm text-gray-600">
                      <div className="flex items-center">
                        <User size={16} className="mr-2" />
                        <span>Report ID: {document.report_id}</span>
                      </div>
                      <div className="flex items-center">
                        <Calendar size={16} className="mr-2" />
                        <span>Date: {document.Date}</span>
                      </div>
                      <div className="flex items-center">
                        <MapPin size={16} className="mr-2" />
                        <span>Type: {document.type ?? 'Unknown'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="px-6 py-4 bg-gray-100">
                    <button
                      onClick={() => navigate(`/documents/fill-documents`, { state: { type: document.type, reportId: document.report_id } })}
                      className="w-full flex justify-between items-center text-sm font-medium text-primary hover:text-primary/90 transition-colors duration-200"
                    >
                      Edit Details
                      <ChevronRight size={18} />
                    </button>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        ) : (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration:  0.5 }}
            className="flex flex-col items-center justify-center h-64 bg-white rounded-xl shadow-lg"
          >
            <FileX size={48} className="text-gray-400 mb-4" />
            <h2 className="text-2xl font-semibold text-gray-700 mb-2">No Documents Found</h2>
            <p className="text-gray-500 text-center max-w-md">
              There are no documents matching your current filters. Try adjusting your search criteria or create a new document.
            </p>
            <motion.button
              onClick={handleNewForm}
              className="mt-6 bg-black text-white px-6 py-3 rounded-full font-medium text-sm transition-all duration-300 ease-in-out hover:bg-gray-800 flex items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <PlusCircle size={18} className="mr-2" />
              Create New Document
            </motion.button>
          </motion.div>
        )}

        {totalPages > 1 && (
          <motion.div 
            className="flex justify-center items-center space-x-2 mt-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1  }}
            transition={{ duration: 0.3 }}
          >
            <motion.button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-lg flex items-center transition-colors duration-300 ${
                currentPage === 1 
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                  : 'bg-primary text-primary-foreground hover:bg-primary/90'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <ChevronLeft size={18} className="mr-1" />
              Previous
            </motion.button>
            {getPageNumbers().map((pageNumber) => (
              <motion.button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={`w-10 h-10 flex items-center justify-center rounded-lg transition-colors duration-300 ${
                  currentPage === pageNumber
                    ? 'bg-primary text-primary-foreground'
                    : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {pageNumber}
              </motion.button>
            ))}
            <motion.button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-lg flex items-center transition-colors duration-300 ${
                currentPage === totalPages 
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                  : 'bg-primary text-primary-foreground hover:bg-primary/90'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Next
              <ChevronRight size={18} className="ml-1" />
            </motion.button>
          </motion.div>
        )}
      </div>
    </div>
  );
}