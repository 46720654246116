import React, { useState, useRef, useEffect } from 'react';

const RenderFiles = ({ files }) => {
  const [previewFile, setPreviewFile] = useState(null);
  const [previewPosition, setPreviewPosition] = useState({ x: 0, y: 0 });
  const previewRef = useRef(null);
  const timeoutRef = useRef(null);

  const handleMouseEnter = (file, event) => {
    clearTimeout(timeoutRef.current);
    setPreviewFile(file);
    updatePreviewPosition(event);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setPreviewFile(null);
    }, 300); // Delay to prevent flickering
  };

  const handleMouseMove = (event) => {
    if (previewFile) {
      updatePreviewPosition(event);
    }
  };

  const updatePreviewPosition = (event) => {
    if (previewRef.current) {
      const rect = previewRef.current.getBoundingClientRect();
      const x = event.clientX + 20; // Increased offset from cursor
      const y = event.clientY - rect.height / 2; // Center vertically

      // Adjust position if it goes off-screen
      const adjustedX = Math.min(x, window.innerWidth - rect.width - 20);
      const adjustedY = Math.max(20, Math.min(y, window.innerHeight - rect.height - 20));

      setPreviewPosition({ x: adjustedX, y: adjustedY });
    }
  };

  const handleDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.fileUrl;
    link.download = file.fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFileType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) return 'image';
    if (['mp4', 'webm', 'ogg'].includes(extension)) return 'video';
    if (['mp3', 'wav', 'ogg'].includes(extension)) return 'audio';
    if (extension === 'pdf') return 'pdf';
    return 'other';
  };

  const renderFilePreview = (file) => {
    const fileType = getFileType(file.fileName);

    switch (fileType) {
      case 'image':
        return <img src={file.fileUrl} alt={file.fileName} className="max-w-full max-h-[300px] object-contain" />;
      case 'video':
        return (
          <video 
            src={file.fileUrl} 
            autoPlay 
            muted 
            loop 
            playsInline 
            className="max-w-full max-h-[300px]"
          >
            Your browser does not support the video tag.
          </video>
        );
      case 'audio':
        return <audio src={file.fileUrl} controls>Your browser does not support the audio tag.</audio>;
      case 'pdf':
        return (
          <iframe 
            src={`${file.fileUrl}#view=FitH`} 
            title={file.fileName}
            className="w-full h-[300px]"
          >
            This browser does not support PDFs. Please download the PDF to view it.
          </iframe>
        );
      default:
        return <p className="text-center">Preview not available. Click to download.</p>;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (previewFile) {
        setPreviewFile(null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [previewFile]);

  return (
    <div className="mt-4">
      <h3 className="text-lg font-semibold mb-2">Attached Files</h3>
      <div className="flex flex-wrap gap-2 mb-4">
        {files.map((file, index) => (
          <button
            key={index}
            onClick={() => handleDownload(file)}
            onMouseEnter={(e) => handleMouseEnter(file, e)}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
            className="px-3 py-1 bg-gray-200 rounded-full text-sm hover:bg-gray-300 transition-colors"
          >
            {file.fileName}
          </button>
        ))}
      </div>
      {previewFile && (
        <div
          ref={previewRef}
          className="fixed z-50 bg-white border border-gray-300 rounded-lg shadow-lg p-4 transition-all duration-300 ease-in-out"
          style={{
            left: `${previewPosition.x}px`,
            top: `${previewPosition.y}px`,
            maxWidth: '400px',
            maxHeight: '400px',
          }}
          onMouseEnter={() => clearTimeout(timeoutRef.current)}
          onMouseLeave={handleMouseLeave}
        >
          <h4 className="text-md font-semibold mb-2">{previewFile.fileName}</h4>
          {renderFilePreview(previewFile)}
        </div>
      )}
    </div>
  );
};

export default RenderFiles;