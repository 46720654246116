import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { User, Phone, Mail, Briefcase, Tag, Key } from 'lucide-react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // Adjust this as needed

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const decodedToken = jwtDecode(token);
        const emp_id = decodedToken.emp_id;

        const response = await axios.get(`${API_URL}/api/profileData`, {
          params: { emp_id },
          headers: { authorization: `${token}` }
        });

        setUserData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user data. Please try again later.');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <motion.div
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="w-16 h-16 border-t-4 border-indigo-500 border-solid rounded-full animate-spin"
        ></motion.div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-xl">{error}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-3xl mx-auto bg-white rounded-xl shadow-2xl overflow-hidden"
      >
        <div className="bg-indigo-600 h-32"></div>
        <div className="px-6 py-8 sm:p-10">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="flex-shrink-0">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  className="mx-auto h-20 w-20 rounded-full border-4 border-white -mt-16 bg-indigo-100"
                  src={`https://ui-avatars.com/api/?name=${userData.name}&background=random`}
                  alt={userData.name}
                />
              </div>
              <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">{userData.name}</p>
                <p className="text-sm font-medium text-gray-600">{userData.role}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 px-6 py-8 sm:p-10 space-y-6">
          {/* <ProfileItem icon={<Mail />} label="Email" value={userData.email} /> */}
          <ProfileItem icon={<Mail />} label="Employee ID" value={userData.emp_id} />
          <ProfileItem icon={<Phone />} label="Contact Number" value={userData.contactNum} />
          <ProfileItem icon={<Briefcase />} label="Role" value={userData.role} />
          <ProfileItem icon={<Tag />} label="Type" value={userData.type} />
        </div>
      </motion.div>
    </div>
  );
};

const ProfileItem = ({ icon, label, value }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg"
  >
    <div className="flex-shrink-0 text-indigo-500">{icon}</div>
    <div className="flex-grow">
      <p className="text-sm font-medium text-gray-500">{label}</p>
      <p className="text-lg font-semibold text-gray-900">{value}</p>
    </div>
  </motion.div>
);

export default Profile;