import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import FormA from './Documents/FormA';
import FormB from './Documents/FormB';
import PressureTestingReport from './Documents/PressureTestingReport';
import Challan from './Documents/Challan';
import ExtinguisherRefillingCertificate from './Documents/ExtinguisherRefillingCertificate';
import ExtinguisherHPTCertificate from './Documents/ExtinguisherHPTCertificate';
import AttendanceForm from './Documents/AttendanceForm';
import LeaveApplication from './Documents/LeaveApplication';
import MissPunchForm from './Documents/MissPunchForm';
import WorkCompletion from './Documents/WorkCompletion';

const TestingAndCommissioningReport = () => <div className="p-4 bg-white rounded shadow">Testing and Commissioning Report Content</div>;
const LoanApplication = () => <div className="p-4 bg-white rounded shadow">Loan Application Content</div>;
const SalaryAdvanceApplication = () => <div className="p-4 bg-white rounded shadow">Salary Advance Application Content</div>;
const SiteAdvanceApplication = () => <div className="p-4 bg-white rounded shadow">Site Advance Application Content</div>;

const formComponents = {
  'Form_A': FormA,
  'Form_B': FormB,
  // 'WORK COMPLETION REPORT': WorkCompletion,
  // 'PRESSURE TESTING REPORT': PressureTestingReport,
  // 'TESTING AND COMMISSIONING REPORT': TestingAndCommissioningReport,
  // 'DELIVERY CHALLAN': Challan,
  // 'RETURNABLE BASIS CHALLAN': Challan,
  // 'FIRE EXTINGUISHER REFILLING CERTIFICATE': ExtinguisherRefillingCertificate,
  // 'FIRE EXTINGUISHER HPT CERTIFICATE': ExtinguisherHPTCertificate,
  // 'ATTENDANCE FORM': AttendanceForm,
  // 'LEAVE APPLICATION': LeaveApplication,
  // 'MISS PUNCH APPLICATION': MissPunchForm,
  // 'LOAN APPLICATION': LoanApplication,
  // 'SALARY ADVANCE APPLICATION': SalaryAdvanceApplication,
  // 'SITE ADVANCE APPLICATION': SiteAdvanceApplication,
};

export default function FillDocuments() {
  const [selectedForm, setSelectedForm] = useState(null);
  const location = useLocation();
  const { type, reportId } = location.state || {};

  useEffect(() => {
    if (type && formComponents[type]) {
      setSelectedForm(type);
    }
  }, [type]);

  const handleFormSelect = (formName) => {
    setSelectedForm(formName === selectedForm ? null : formName);
  };

  const renderForm = () => {
    if (selectedForm) {
      const FormComponent = formComponents[selectedForm];
      return <FormComponent reportId={reportId} />;
    }
    return null;
  };

  return (
    <div className="min-h-screen p-8">
      <div className="max-w-7xl mx-auto">
        <motion.h2 
          className="text-3xl font-bold text-gray-800 mb-8 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {type && reportId ? 'Edit Document' : 'Fill Forms'}
        </motion.h2>
        
        {!type && (
          <motion.div 
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, staggerChildren: 0.1 }}
          >
            {Object.keys(formComponents).map((formName) => (
              <motion.button
                key={formName}
                onClick={() => handleFormSelect(formName)}
                className={`p-3 rounded-lg text-sm font-medium transition-all duration-200 shadow-md hover:shadow-lg ${
                  selectedForm === formName
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-800 hover:bg-gray-100'
                }`}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
              >
                {formName.replace(/_/g, ' ')}
              </motion.button>
            ))}
          </motion.div>
        )}

        <AnimatePresence mode="wait">
          {selectedForm && (
            <motion.div
              key={selectedForm}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              {/* <h3 className="text-xl font-semibold mb-4 text-gray-800">
                {selectedForm.replace(/_/g, ' ')}
              </h3> */}
              {renderForm()}
            </motion.div>
          )}
        </AnimatePresence>

        {!selectedForm && !type && (
          <motion.p
            className="text-center text-gray-600 mt-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Please select a form to fill out.
          </motion.p>
        )}
      </div>
    </div>
  );
}